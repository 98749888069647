import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuIcon from "@mui/icons-material/Menu";
import { useState, Fragment } from "react";
import { IconButton, Typography } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { IoCard, IoEarthOutline, IoLogOutOutline } from "react-icons/io5";
import { BsFillBoxSeamFill, BsPercent } from "react-icons/bs";
import { useTheme } from "@mui/material/styles";
import { BiGift, BiSolidHelpCircle } from "react-icons/bi";
import { PiHandCoinsFill } from "react-icons/pi";
import LangForm from "./LangForm";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FiFileText } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../../app/features/auth/auth";
import { notifyWithError } from "../../../app/features/error/error";
import { FaRegBuilding } from "react-icons/fa";

const NavbarDrawer = () => {
  //SECTION - GENERAL
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //SECTION - useState
  const [state, setState] = useState(false);
  const [openLangList, setOpenLangList] = useState(false);

  //SECTION - CUSTOM STYLES
  const listItemStyle = {
    borderRadius: "5px",
    height: "50px",
    backgroundColor: theme.palette.gray.g50,
    border: `1px solid ${theme.palette.gray.g100} `,
    "&:hover": {
      border: `1px solid ${theme.palette.secondary.main} `,
    },
  };

  //SECTION - FUNCTIONS
  const handleClick = () => {
    setOpenLangList(!openLangList);
  };

  const handleCloseDrawer = () => {
    setState((prevValue) => !prevValue);
  };

  const toggleDrawer = () => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState((prevValue) => !prevValue);
  };

  //SECTION - useSelector
  const rewardsClientValidity = useSelector(
    (state) => state.rewardsReducer.clientValidForRewards
  );

  return (
    <Box>
      {
        <Fragment>
          <IconButton
            onClick={toggleDrawer()}
            sx={{
              display: { xs: "flex" },
              "@media (min-width: 740px)": {
                display: "none",
              },
              padding: theme.spacing(2),
            }}
            edge="start"
            aria-label="open drawer"
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            anchor={localStorage.getItem("lng") === "ar" ? "right" : "left"}
            open={state}
            onClose={toggleDrawer()}
          >
            <Box p={"1rem"}>
              <List
                sx={{
                  width: "60vw",
                  maxWidth: 360,
                  display: "flex",
                  flexDirection: "column",
                  gap: theme.spacing(1),
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
              >
                <ListItemButton
                  sx={listItemStyle}
                  onClick={() => {
                    navigate("/orders");
                    handleCloseDrawer();
                  }}
                >
                  <ListItemIcon>
                    <BsFillBoxSeamFill
                      color={theme.palette.secondary.main}
                      fontSize={"1.5rem"}
                    />
                  </ListItemIcon>
                  <Typography>{t("navbar.orders")}</Typography>
                </ListItemButton>
                <ListItemButton
                  sx={listItemStyle}
                  onClick={() => {
                    navigate("/offers");
                    handleCloseDrawer();
                  }}
                >
                  <ListItemIcon>
                    <BsPercent
                      color={theme.palette.secondary.main}
                      fontSize={"1.5rem"}
                    />
                  </ListItemIcon>
                  <Typography>{t("navbar.offers")}</Typography>
                </ListItemButton>
                {rewardsClientValidity && (
                  <ListItemButton
                    sx={listItemStyle}
                    onClick={() => {
                      navigate("/rewards");
                      handleCloseDrawer();
                    }}
                  >
                    <ListItemIcon>
                      <BiGift
                        color={theme.palette.secondary.main}
                        fontSize={"1.5rem"}
                      />
                    </ListItemIcon>
                    <Typography>{t("navbar.rewards")}</Typography>
                  </ListItemButton>
                )}
                {/* <ListItemButton
                  sx={listItemStyle}
                  onClick={() => {
                    navigate("/wallet");
                    handleCloseDrawer();
                  }}
                >
                  <ListItemIcon>
                    <IoWallet
                      color={theme.palette.secondary.main}
                      fontSize={"1.5rem"}
                    />
                  </ListItemIcon>
                  <Typography>{t("navbar.wallet")}</Typography>
                </ListItemButton> */}
                <ListItemButton
                  sx={listItemStyle}
                  onClick={() => {
                    navigate("/installment-services");
                    handleCloseDrawer();
                  }}
                >
                  <ListItemIcon>
                    <PiHandCoinsFill
                      color={theme.palette.secondary.main}
                      fontSize={"1.5rem"}
                    />
                  </ListItemIcon>
                  <Typography>{t("navbar.installmentService")}</Typography>
                </ListItemButton>
                {/* //TODO - Remove comment when enabling feature (3/3) */}
                {/* <ListItemButton
                  sx={listItemStyle}
                  onClick={() => {
                    navigate("/branches");
                    handleCloseDrawer();
                  }}
                >
                  <ListItemIcon>
                    <FaRegBuilding
                      color={theme.palette.secondary.main}
                      fontSize={"1.5rem"}
                    />
                  </ListItemIcon>
                  <Typography>{t("navbar.branches")}</Typography>
                </ListItemButton> */}
                {/* <ListItemButton
                  sx={listItemStyle}
                  onClick={() => {
                    navigate("/rewards");
                    handleCloseDrawer();
                  }}
                >
                  <ListItemIcon>
                    <BiGift
                      color={theme.palette.secondary.main}
                      fontSize={"1.5rem"}
                    />
                  </ListItemIcon>
                  <Typography>{t("navbar.rewardsSystem")}</Typography>
                </ListItemButton> */}
                <ListItemButton
                  sx={listItemStyle}
                  onClick={() =>
                    window.open(
                      "https://deals-uploads.s3.eu-central-1.amazonaws.com/public/files/general/Web+Ordering+steps_02+(2)+(1).pdf"
                    )
                  }
                >
                  <ListItemIcon>
                    <BiSolidHelpCircle
                      color={theme.palette.secondary.main}
                      fontSize={"1.5rem"}
                    />
                  </ListItemIcon>
                  <Typography>{t("navbar.usageInstructions")}</Typography>
                </ListItemButton>

                {/* <ListItemButton sx={listItemStyle}>
                  <ListItemIcon>
                    <IoPersonOutline
                      color={theme.palette.secondary.main}
                      fontSize={"1.5rem"}
                    />
                  </ListItemIcon>
                  <Typography>{t("navbar.myProfile")}</Typography>
                </ListItemButton>
                <ListItemButton sx={listItemStyle}>
                  <ListItemIcon>
                    <IoWallet
                      color={theme.palette.secondary.main}
                      fontSize={"1.5rem"}
                    />
                  </ListItemIcon>
                  <Typography>{t("navbar.wallet")}</Typography>
                </ListItemButton> */}

                <ListItemButton
                  sx={listItemStyle}
                  onClick={() => {
                    navigate("/payment");
                    handleCloseDrawer();
                  }}
                >
                  <ListItemIcon>
                    <IoCard
                      color={theme.palette.secondary.main}
                      fontSize={"1.5rem"}
                    />
                  </ListItemIcon>
                  <Typography>{t("navbar.paymentMethods")}</Typography>
                </ListItemButton>
                {/* <ListItemButton sx={listItemStyle}>
                  <ListItemIcon>
                    <IoSwapVertical
                      color={theme.palette.secondary.main}
                      fontSize={"1.5rem"}
                    />
                  </ListItemIcon>
                  <Typography>{t("navbar.brands")}</Typography>
                </ListItemButton> */}
                {/* <ListItemButton
                  sx={listItemStyle}
                  onClick={() => {
                    navigate("/FAQ");
                    handleCloseDrawer();
                  }}
                >
                  <ListItemIcon>
                    <IoHelpCircle
                      color={theme.palette.secondary.main}
                      fontSize={"1.5rem"}
                    />
                  </ListItemIcon>
                  <Typography>{t("navbar.FAQ")}</Typography>
                </ListItemButton> */}
                <ListItemButton
                  sx={listItemStyle}
                  onClick={() => {
                    navigate("/terms");
                    handleCloseDrawer();
                  }}
                >
                  <ListItemIcon>
                    <FiFileText
                      color={theme.palette.secondary.main}
                      fontSize={"1.5rem"}
                    />
                  </ListItemIcon>
                  <Typography>{t("navbar.termsAndConditions")}</Typography>
                </ListItemButton>

                <ListItemButton sx={listItemStyle} onClick={handleClick}>
                  <ListItemIcon>
                    <IoEarthOutline
                      color={theme.palette.secondary.main}
                      fontSize={"1.5rem"}
                    />
                  </ListItemIcon>
                  <Typography>{t("navbar.languages")}</Typography>
                  {openLangList ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openLangList} timeout="auto" unmountOnExit>
                  <LangForm
                    isInDrawer={"true"}
                    handleCloseDrawer={handleCloseDrawer}
                  />
                </Collapse>
                {/* <ListItemButton sx={listItemStyle}>
                  <ListItemIcon>
                    <BiSupport
                      color={theme.palette.secondary.main}
                      fontSize={"1.5rem"}
                    />
                  </ListItemIcon>
                  <Typography>{t("navbar.support")}</Typography>
                </ListItemButton> */}
                <ListItemButton
                  onClick={() => {
                    dispatch(logOut()).then((payload) => {
                      if (payload.type === "auth/logOut/fulfilled") {
                        navigate("/login");
                      } else {
                        dispatch(
                          notifyWithError({
                            message: t("errorCodes.logOutError"),
                            type: "error",
                          })
                        );
                      }
                    });
                  }}
                  sx={{
                    backgroundColor: theme.palette.error.main,
                    color: theme.palette.white,
                    borderRadius: "5px",
                    height: "50px",
                    "&:hover": {
                      backgroundColor: theme.palette.error.dark,
                    },
                  }}
                >
                  <ListItemIcon>
                    <IoLogOutOutline color={theme.palette.white} />
                  </ListItemIcon>
                  <Typography>{t("navbar.logout")}</Typography>
                </ListItemButton>
              </List>
            </Box>
          </Drawer>
        </Fragment>
      }
    </Box>
  );
};

export default NavbarDrawer;
