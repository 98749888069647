import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ar from "./locales/ar.json";
import en from "./locales/en.json";
import roman from "./locales/roman.json";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
    ar: {
      translation: ar,
    },
    hi: {
      translation: roman,
    },
  },
  lng: localStorage.getItem("lng") || "ar", // Set the default language
  fallbackLng: "en", // Use the fallback language if a translation is missing
  interpolation: {
    escapeValue: false, // React already escapes the content, so no need for additional escaping
  },
});

export default i18n;
