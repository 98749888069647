import ReactGA from "react-ga4";
const GA4_ID = process.env.REACT_APP_GA4_ID;

export const useGoogleAnalytics = () => {
  const initGA4 = () => {
    if (!ReactGA.isInitialized) {
      ReactGA.initialize(GA4_ID, {
        testMode: !process.env.REACT_APP_ENV === "production",
      });
    }
  };
  const logUserProperties = (userId, userAttributes) => {
    ReactGA.set({ userId, ...userAttributes });
  };

  const logPageView = (title) => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title,
    });
  };

  const logPurchase = (purchaseData) => {
    ReactGA.gtag("event", "purchase", purchaseData);
  };

  const logAddToCart = (cartItems) => {
    const cartData = {
      cart_value: cartItems.reduce(
        (acc, item) =>
          acc +
          item?.items_detail?.selling_price * item?.items_detail?.quantity,
        0
      ),

      items: cartItems.map((item) => ({
        item_id: item?.id,
        item_name: item?.item?.part_number,
        item_brand: item?.brand?.name_en,
        item_category: item?.item?.manufacturer?.name_en,
        price: parseFloat(item?.items_detail?.selling_price) || 0,
        quantity: item?.items_detail?.quantity || 0,
        coupon: item?.offer_id,
      })),
    };
    ReactGA.gtag("event", "add_to_cart", {
      currency: "SAR",
      value: cartData.cart_value,
      items: cartData.items,
    });
  };

  const logRemoveFromCart = (cartItems) => {
    const cartData = {
      cart_value: cartItems.reduce(
        (acc, item) =>
          acc +
          item?.items_detail?.selling_price * item?.items_detail?.quantity,
        0
      ),
      items: cartItems.map((item) => ({
        item_id: item?.id,
        item_name: item?.item.part_number,
        item_brand: item?.brand.name_en,
        item_category: item?.item?.manufacturer.name_en,
        price: parseFloat(item?.items_detail?.selling_price) || 0,
        quantity: item?.items_detail?.quantity || 0,
        coupon: item?.offer_id,
      })),
    };
    ReactGA.gtag("event", "remove_from_cart", {
      currency: "SAR",
      value: cartData.cart_value,
      items: cartData.items,
    });
  };

  return {
    logUserProperties,
    logPageView,
    logPurchase,
    logAddToCart,
    initGA4,
    logRemoveFromCart,
  };
};
