import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import FindBrandLogo from "../../../utils/data/FindBrandLogo";
import {
  Grid,
  Typography,
  useTheme,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  List,
  LinearProgress,
  Box,
} from "@mui/material";
import BrandListItem from "./BrandListItem";

const ListItem = ({
  item,
  handleRefinement,
  brands,
  firstItem,
  lastItem,
  selectedItem,
  setSelectedItem,
}) => {
  //SECTION - general
  const theme = useTheme();

  //SECTION - function
  const handleClick = () => {
    handleRefinement(item);
    setSelectedItem(item.value === selectedItem ? null : item.value);
  };

  return (
    <>
      <ListItemButton
        onClick={handleClick}
        sx={{
          borderBottom: !lastItem && `2px solid ${theme.palette.gray.g200}`,
          "&:hover": {
            backgroundColor: theme.palette.gray.g100,
          },
          borderTopRightRadius: firstItem && theme.borderRadius.r10,
          borderTopLeftRadius: firstItem && theme.borderRadius.r10,
          borderBottomRightRadius: lastItem && theme.borderRadius.r10,
          borderBottomLeftRadius: lastItem && theme.borderRadius.r10,
        }}
      >
        <ListItemIcon>
          <img
            style={{
              width: "35px",
              height: "35px",
              objectFit: "contain",
            }}
            src={FindBrandLogo(brands, item.label)}
            alt={item.label}
          />
        </ListItemIcon>
        <ListItemText>
          <Grid
            direction={"row"}
            container
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography fontWeight={theme.typography.bold}>
              {item.label}
            </Typography>
            <Typography
              bgcolor={theme.palette.secondary.main}
              borderRadius={theme.borderRadius.r10}
              padding={theme.spacing(0.5)}
              color={"white"}
              textAlign={"center"}
              width={"45px"}
            >
              {item.count}
            </Typography>
          </Grid>
        </ListItemText>
        {selectedItem === item.value ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={selectedItem === item.value} unmountOnExit>
        <List component="div" disablePadding>
          {item.data ? (
            item.data.map((brand) => {
              return (
                <BrandListItem
                  key={brand.label}
                  brand={brand}
                  handleRefinement={handleRefinement}
                />
              );
            })
          ) : (
            <Box width={"100%"}>
              <LinearProgress color="secondary" />
            </Box>
          )}
        </List>
      </Collapse>
    </>
  );
};

export default ListItem;
