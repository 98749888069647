import {
  FormControl,
  FormHelperText,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { Stack, useTheme } from "@mui/system";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";

const StyledInput = ({
  title,
  icon,
  value,
  onChange,
  placeholder,
  onKeyPress,
  name,
  onBlur,
  error,
  helperText,
  height = "40px",
  color = "primary",
}) => {
  //SECTION - general
  const theme = useTheme();
  const { i18n } = useTranslation();

  // Use a ref to access the input element
  const inputRef = useRef(null);
  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.select(); // Select the text in the input
    }
  };

  return (
    <Stack sx={{ width: "100%" }}>
      <Typography
        variant={"body2"}
        color={theme.palette.gray.g600}
        fontWeight={"bold"}
        paddingBottom={"0.25rem"}
      >
        {title}
      </Typography>
      <FormControl
        fullWidth
        error={error} // Pass the error prop to FormControl
        variant="outlined"
      >
        <OutlinedInput
          name={name}
          onBlur={onBlur}
          color={color}
          sx={{
            borderRadius: "10px",
            height: height,
            backgroundColor: "white",
            width: "100%",
            px: "1rem",
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor:
                color === "secondary"
                  ? theme.palette.secondary.main
                  : theme.palette.primary.main, // Focused border color
            },
          }}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onKeyPress={onKeyPress}
          endAdornment={<InputAdornment position="end">{icon}</InputAdornment>}
          inputRef={inputRef} // Attach ref to the input element
          onClick={handleClick} // Use the handleClick function
        />
        {helperText && (
          <FormHelperText
            sx={{
              textAlign: i18n.language === "ar" ? "right" : "left", // Correct text alignment
            }}
          >
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    </Stack>
  );
};

export default StyledInput;
