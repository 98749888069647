import {
  Box,
  Grid,
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
  Backdrop,
  CircularProgress,
  Divider,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import OrderBottomDrawer from "./OrderBottomDrawer";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOrders,
  selectOrders,
  currentlySelectedOrder,
  currentlySelectedOrderId,
  selectOrder,
  fetchSpecificOrder,
  orderStatus,
  getFetchSpecificOrderStatus,
  getOrdersRequiringConfirmation,
  getRegularOrders,
  getOrdersPagination,
  updateCurentPage,
  getCurrentPage,
} from "../../app/features/order/order";
import { fetchBrands } from "../../app/features/brand/brand";
import OrderCard from "./OrderCard";
import SelectedOrderDetails from "./SelectedOrderDetails";
import IsEmpty from "../../utils/validation/IsEmpty";
import { TbPackageOff } from "react-icons/tb";
import { BiError } from "react-icons/bi";
import InfiniteScroll from "react-infinite-scroll-component";

const Orders = () => {
  //SECTION - general
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("semiMd"));
  const isTabletScreen = useMediaQuery(theme.breakpoints.up("semiMd"));
  const { t } = useTranslation();
  const navigate = useNavigate();

  //SECTION - useState
  const [open, setOpen] = useState(false);
  const [UIUpdatedOrderId, setUIUpdatedOrderId] = useState("");

  //SECTION - useSelector
  const orders = useSelector(selectOrders);
  const brands = useSelector((state) => state.brandReducer.brands);
  const currentOrder = useSelector(currentlySelectedOrder);
  const selectedOrderId = useSelector(currentlySelectedOrderId);
  const orderLoadingStatus = useSelector(orderStatus);
  const fetchSpecificOrderStatus = useSelector(getFetchSpecificOrderStatus);
  const unconfirmedOrders = useSelector(getOrdersRequiringConfirmation);
  const regularOrders = useSelector(getRegularOrders);
  const ordersPagination = useSelector(getOrdersPagination);
  const current_page = useSelector(getCurrentPage);

  //SECTION - useEffect
  useEffect(() => {
    dispatch(fetchOrders({ page: current_page }));
    dispatch(fetchBrands());
  }, [dispatch, current_page]);

  useEffect(() => {
    if (brands.length === 0) {
      dispatch(fetchBrands());
    }
  }, [brands, dispatch]);

  useEffect(() => {
    //display the first order details by default only at desktop when the user enters orders page for first time
    if (orders) {
      if (orders?.length > 0 && !isSmallScreen && IsEmpty(selectedOrderId)) {
        const firstOrderId = orders[0]?.id;
        if (selectedOrderId !== firstOrderId) {
          dispatch(fetchSpecificOrder(firstOrderId));
          dispatch(selectOrder(firstOrderId));
        }
      }
    }
  }, [orders, dispatch, isSmallScreen, selectedOrderId]);

  //NOTE - automatically open bottom drawer in case an order was fetched to handle cases such as redirection from cart checkout
  useEffect(() => {
    if (isSmallScreen && selectedOrderId) {
      handleOpen();
    }
  }, [isSmallScreen, selectedOrderId]);

  //SECTION - functions
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSubmit = (orderId) => {
    dispatch(fetchSpecificOrder(orderId));
    dispatch(selectOrder(orderId));
    if (isSmallScreen) {
      return handleOpen();
    } else {
      return undefined;
    }
  };
  const refetchOrder = (orderId) => dispatch(fetchSpecificOrder(orderId));
  const updateUIOrderId = (orderId) => {
    setUIUpdatedOrderId(orderId);
  };

  const loadMoreOrders = () => {
    if (current_page >= ordersPagination || orderLoadingStatus === "loading")
      return;
    dispatch(updateCurentPage(current_page + 1));
    dispatch(fetchOrders({ page: current_page + 1 }));
  };

  return (
    <>
      {orderLoadingStatus === "failed" ? (
        <Grid
          xs={12}
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          height={"80vh"}
        >
          <BiError
            color={theme.palette.error.main}
            fontSize={theme.spacing(30)}
          />
          <Typography variant="h6" fontWeight={theme.typography.bold}>
            {t("order.fetchOrdersError")}
          </Typography>
        </Grid>
      ) : orders ? (
        orders.length > 0 ? (
          <Grid container padding={theme.spacing(1)}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              marginTop={theme.spacing(2)}
              // position={"relative"}
            >
              <Grid
                id="orders"
                container
                top={0}
                xs={12}
                semiMd={5}
                md={4.5}
                lg={4}
                xxl={3.5}
                paddingX={isTabletScreen && theme.spacing(1)}
                height="fit-content"
                maxHeight="85vh"
                sx={{ overflowY: "auto" }}
              >
                <InfiniteScroll
                  dataLength={orders.length}
                  next={() => loadMoreOrders()}
                  hasMore={current_page < ordersPagination}
                  loader={
                    <Box
                      display={"flex"}
                      padding={theme.spacing(2)}
                      alignContent={"center"}
                      justifyContent={"center"}
                    >
                      <CircularProgress />
                    </Box>
                  }
                  scrollThreshold={0.95}
                  scrollableTarget="orders"
                  style={{ scrollbarWidth: "none" }}
                >
                  {(unconfirmedOrders === null ||
                    (unconfirmedOrders !== null &&
                      unconfirmedOrders.length === 0)) && (
                    <Stack direction={"column"} spacing={2} width={"100%"}>
                      <>
                        {orders.map((order) => {
                          return (
                            <OrderCard
                              key={order.id}
                              order={order}
                              UIUpdatedOrderId={UIUpdatedOrderId}
                              handleSubmit={handleSubmit}
                            />
                          );
                        })}
                      </>
                    </Stack>
                  )}
                  {unconfirmedOrders !== null &&
                    unconfirmedOrders.length !== 0 && (
                      <Stack direction={"column"} spacing={2} width={"100%"}>
                        <>
                          <Typography
                            fontWeight={theme.typography.bold}
                            fontSize={"2rem"}
                          >
                            {t("order.waitingApprovementOrders")}
                          </Typography>
                          {unconfirmedOrders.map((order) => {
                            return (
                              <OrderCard
                                key={order.id}
                                order={order}
                                UIUpdatedOrderId={UIUpdatedOrderId}
                                handleSubmit={handleSubmit}
                              />
                            );
                          })}
                        </>
                      </Stack>
                    )}

                  <Divider />
                  <>
                    {unconfirmedOrders !== null &&
                      unconfirmedOrders.length !== 0 &&
                      unconfirmedOrders.length !== orders.length && (
                        <Stack
                          direction={"column"}
                          spacing={2}
                          width={"100%"}
                          paddingTop={theme.spacing(1)}
                        >
                          <Typography
                            fontWeight={theme.typography.bold}
                            fontSize={"2rem"}
                          >
                            {t("order.regularOrders")}
                          </Typography>

                          {regularOrders.map((order) => {
                            return (
                              <OrderCard
                                key={order.id}
                                order={order}
                                UIUpdatedOrderId={UIUpdatedOrderId}
                                handleSubmit={handleSubmit}
                              />
                            );
                          })}
                        </Stack>
                      )}
                  </>
                </InfiniteScroll>
              </Grid>

              {fetchSpecificOrderStatus === "succeeded"
                ? currentOrder && (
                    <>
                      <SelectedOrderDetails
                        selectedOrder={currentOrder}
                        brands={brands}
                        variant="rounded"
                        refetchOrder={refetchOrder}
                        updateUIOrderId={updateUIOrderId}
                      />
                      <OrderBottomDrawer
                        open={open}
                        handleOpen={handleOpen}
                        handleClose={handleClose}
                        selectedOrder={currentOrder}
                        brands={brands}
                        refetchOrder={refetchOrder}
                        updateUIOrderId={updateUIOrderId}
                      />
                    </>
                  )
                : !isSmallScreen &&
                  (fetchSpecificOrderStatus !== "failed" ? (
                    <Grid
                      semiMd={6.8}
                      md={7.4}
                      lg={7.8}
                      xxl={8.5}
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ height: "80vh" }}
                    >
                      <CircularProgress size={100} />
                    </Grid>
                  ) : (
                    <Grid
                      semiMd={6.8}
                      md={7.4}
                      lg={7.8}
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <BiError
                        color={theme.palette.error.main}
                        fontSize={theme.spacing(30)}
                      />
                      <Typography
                        variant="h6"
                        fontWeight={theme.typography.bold}
                      >
                        {t("order.fetchOrderError")}
                      </Typography>
                    </Grid>
                  ))}
            </Grid>
          </Grid>
        ) : (
          <Box p={theme.spacing(3)}>
            <Grid
              container
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              height={"80vh"}
            >
              <TbPackageOff
                fontSize={theme.spacing(30)}
                color={theme.palette.primary.main}
              />
              <Typography fontWeight={theme.typography.bold} variant="h6">
                {t("order.empty")}
              </Typography>

              <Typography
                onClick={() => navigate("/")}
                variant="h6"
                fontWeight={theme.typography.bold}
                color={theme.palette.secondary.main}
                sx={{ cursor: "pointer" }}
              >
                {t("order.shopNow")}
              </Typography>
            </Grid>
          </Box>
        )
      ) : (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={true}
          onClick={handleClose}
        >
          <Grid
            container
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            textAlign={"center"}
            gap={theme.spacing(2)}
          >
            <CircularProgress size={isSmallScreen ? 100 : 250} />
            <Typography fontSize={"1.5rem"}>{t("order.loading")}</Typography>
          </Grid>
        </Backdrop>
      )}
    </>
  );
};
export default Orders;
