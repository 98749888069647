import CustomRefinement from "./CustomRefinement";
import { useTheme, useMediaQuery, Chip, Typography } from "@mui/material";
import { IoSearchOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { useSearchBox } from "react-instantsearch";
import { Stack } from "@mui/system";
import StyledInput from "../../../components/common/StyledInput";
import { useEffect, useRef, useState } from "react";
import { useSmartlook } from "../../../utils/api/smartlook";

const CustomSearchBox = ({ setTriggerFilterBrands, triggerFilterBrands }) => {
  //SECTION - general
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("semiLg"));
  const debounceTimer = useRef(null);
  const { trackCustomEvent } = useSmartlook();
  const { refine } = useSearchBox();

  //SECTION - useState
  const [searchQuery, setSearchQuery] = useState("");
  const [memorizedSearchList, setMemorizedSearchList] = useState([]);

  //SECTION - functions
  const handleSearch = (query) => {
    setSearchQuery(query);
    refine(query);
  };

  const handleInputChange = (e) => {
    const query = e.target.value;

    handleSearch(query);

    // clear the previous time
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    // set new time for debouncing and register user interest if the user stops typing for 5 seconds
    // and the typed text exceeded 3 characters

    debounceTimer.current = setTimeout(() => {
      if (query.length >= 3) {
        setMemorizedSearchList((prev) => {
          if (!prev.includes(query)) {
            const updatedList = [query, ...prev];
            // ensure the list does not exceed a length of 5
            const trimmedList =
              updatedList.length > 5
                ? updatedList.slice(updatedList.length - 5)
                : updatedList;
            localStorage.setItem("searchItems", JSON.stringify(trimmedList));
            return trimmedList;
          } else {
            return prev;
          }
        });
      }
    }, 5000);
  };

  const handleDeleteMemorizedSearchItem = (searchItem) => {
    setMemorizedSearchList((prev) => {
      const updatedList = prev.filter((item) => item !== searchItem);
      localStorage.setItem("searchItems", JSON.stringify(updatedList));
      return updatedList;
    });
  };

  const handleBadgeClick = (searchItem, index) => {
    setTriggerFilterBrands(true);
    setTimeout(() => {
      handleSearch(searchItem);
      trackCustomEvent("Previous Search Clicked", {
        part_number: searchItem,
        position: index,
      });
    }, 100); // 100 milliseconds delay
  };
  //SECTION - useEffect
  // cleanup timer on component unmount
  useEffect(() => {
    return () => {
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }
    };
  }, []);

  // retrieve the stored search items when the component mounts
  useEffect(() => {
    const storedItems = JSON.parse(localStorage.getItem("searchItems"));
    if (Array.isArray(storedItems)) {
      setMemorizedSearchList(storedItems);
    }
  }, []);
  return (
    <Stack
      sx={{
        backgroundColor: "gray.g100",
        marginY: { xs: "0.5rem", sm: "2rem" },
        paddingX: {
          xs: "0.75rem",
          sm: "2rem",
        },
        paddingY: "0.75rem",
        zIndex: 999,
        position: "sticky",
        top: { xs: "65px", semiSm: "85px" },
      }}
      gap={"0.5rem"}
    >
      <StyledInput
        icon={<IoSearchOutline />}
        placeholder={t("home.search")}
        value={searchQuery}
        onChange={(e) => handleInputChange(e)}
      />
      {memorizedSearchList?.length > 0 && (
        <Stack
          direction={{ xs: "column", semiLg: "row" }}
          gap={"0.25rem"}
          alignItems={{ xs: "flex-start", semiLg: "center" }}
        >
          <Typography
            variant="body2"
            fontWeight={600}
            color="gray.g500"
            whiteSpace={"nowrap"}
          >
            {t("labels.recentSearchResults")}
          </Typography>
          <Stack
            direction={"row"}
            alignItems={"center"}
            overflow={"auto"}
            gap={{ xs: "0.5rem", semiLg: "0.25rem", width: "100%" }}
          >
            {memorizedSearchList?.map((searchItem, index) => {
              if (isSmallScreen && index >= 3) {
                return null; // Skip rendering items beyond the third one
              }
              return (
                <Chip
                  key={searchItem}
                  dir="ltr"
                  onDelete={() => handleDeleteMemorizedSearchItem(searchItem)}
                  onClick={() => handleBadgeClick(searchItem, index)}
                  sx={{
                    backgroundColor: "gray.g300",
                  }}
                  label={
                    <Typography
                      variant="h6"
                      sx={{
                        paddingX: { xs: "0.5rem", semiLg: "0rem" },
                        color: "gray.g500",
                      }}
                    >
                      {searchItem}
                    </Typography>
                  }
                />
              );
            })}
          </Stack>
        </Stack>
      )}

      {isSmallScreen && (
        <CustomRefinement
          limit={100}
          attribute="manufacturer.name_en"
          triggerFilterBrands={triggerFilterBrands}
          setTriggerFilterBrands={setTriggerFilterBrands}
        />
      )}
    </Stack>
  );
};
export default CustomSearchBox;
