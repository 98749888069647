//SECTION - GENERAL
import React, { useEffect } from "react";
import Snackbar from "../src/components/common/Snackbar";
import "./App.css";
import WrapperNotAuthed from "./components/Template/Wrapper/WrapperNotAuthed";

//SECTION - PAGES
import LoginWithOTP from "./views/Login/LoginWithOTP";
import LoginWithPhone from "./views/Login/LoginWithPhone";
import Home from "./views/Home/Home";
import Cart from "./views/Cart/Cart";
import Payment from "./views/Payment/Payment";
import TermsAndConditions from "./views/TermsAndConditions/TermsAndConditions";
import Wrapper from "./components/Template/Wrapper/Wrapper";
import Orders from "./views/Orders/Orders";
import Offers from "./views/Offers/Offers";
import Wallet from "./views/Wallet/Wallet";
import ErrorPage from "./components/Template/Error/ErrorPage";
import WalletCreateInvoice from "./views/Wallet/WalletCreateInvoice";
import Rewards from "./views/Rewards/Rewards";
import OrderTracking from "./views/OrderTracking/OrderTracking";

//SECTION - ROUTES
import { Route, Routes, Navigate } from "react-router-dom";
import AuthProtected from "./routes/AuthProtected";
import LoginProtected from "./routes/LoginProtected";
import OTPProtected from "./routes/OTPProtected";

//SECTION - PROVIDERS
import { I18nextProvider, useTranslation } from "react-i18next";
import theme from "./utils/materialUI/Theme";
import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Smartlook, { useSmartlook } from "./utils/api/smartlook";
import {
  isAuthenticated,
  validateWatchtowerToken,
} from "./utils/api/Authentication";
import { jwtDecode } from "jwt-decode";
import { checkBetaUser, updateUserLang } from "./app/features/auth/auth";
import IsEmpty from "./utils/validation/IsEmpty";
import IndexWallet from "./views/Wallet/IndexWallet";
import { initSentry, setUserContext } from "./utils/api/sentry";
import BranchesIndex from "./views/Branches/pages/BranchesIndex";
import { useGoogleAnalytics } from "./utils/api/googleAnalytics";

initSentry();

const App = () => {
  //SECTION - Translation realted
  const { i18n } = useTranslation();
  document.body.dir = i18n.dir();
  const { setUserAttributes, alreadySetUserAttributes } = useSmartlook();
  const dispatch = useDispatch();
  const { logUserProperties, initGA4 } = useGoogleAnalytics();

  //SECTION - useSelector
  const betaUser = useSelector((state) => state.authReducer.betaUser);

  useEffect(() => {
    initGA4();
    if (
      isAuthenticated() &&
      !alreadySetUserAttributes &&
      validateWatchtowerToken() &&
      !IsEmpty(betaUser)
    ) {
      const user = jwtDecode(localStorage.getItem("watchTowerAccessToken"));
      const userLang = localStorage.getItem("lng");
      //update google analytics user properties
      logUserProperties(user.user_id, {
        name: `${user.user.first_name} ${user.user.last_name}`,
        phone: user.user.phone,
        is_staff: user.user.is_staff,
        isBetaUser: betaUser,
        lang: userLang,
      });
      //update smartlook user attributes
      setUserAttributes(user.user_id, {
        name: `${user.user.first_name} ${user.user.last_name}`,
        phone: user.user.phone,
        is_staff: user.user.is_staff,
        language: userLang,
        isBetaUser: betaUser,
      });
      //update sentry user attributes
      setUserContext(user.user_id, {
        phone: user.user.phone,
        is_staff: user.user.is_staff,
        isBetaUser: betaUser,
        lang: userLang,
      });
      //update user language in DB
      if (userLang) {
        dispatch(
          updateUserLang({ lang: localStorage.getItem("lng").toUpperCase() })
        );
      } else {
        localStorage.setItem("lng", i18n.language);
        dispatch(updateUserLang({ lang: i18n.language.toUpperCase() }));
      }
    }
  }, [
    setUserAttributes,
    betaUser,
    alreadySetUserAttributes,
    dispatch,
    i18n.language,
    initGA4,
    logUserProperties,
  ]);

  useEffect(() => {
    //check beta user
    if (localStorage.getItem("watchTowerAccessToken")) {
      dispatch(checkBetaUser());
    }
  }, [dispatch]);

  return (
    <div className="root2">
      <Smartlook />
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Snackbar />
          <Routes>
            <Route element={<LoginProtected />}>
              <Route
                path="/login"
                element={<WrapperNotAuthed component={<LoginWithPhone />} />}
              />
            </Route>
            <Route element={<OTPProtected />}>
              <Route
                path="/otp"
                element={<WrapperNotAuthed component={<LoginWithOTP />} />}
              />
            </Route>

            <Route element={<AuthProtected />}>
              <Route path="/home" element={<Wrapper component={<Home />} />} />
              <Route path="/cart" element={<Wrapper component={<Cart />} />} />
              <Route
                path="/orders"
                element={<Wrapper component={<Orders />} />}
              />
              <Route
                path="/offers"
                element={<Wrapper component={<Offers />} />}
              />
              <Route
                path="/payment"
                element={<Wrapper component={<Payment />} />}
              />
              <Route
                path="/rewards"
                element={<Wrapper component={<Rewards />} />}
              />
              {/* <Route path="/FAQ" element={<Wrapper component={<FAQ />} />} /> */}
              <Route
                path="/terms"
                element={<Wrapper component={<TermsAndConditions />} />}
              />
              <Route
                path="/installment-services"
                element={<Wrapper component={<IndexWallet />} />}
              />
              <Route
                path="/wallet"
                element={<Wrapper component={<Wallet />} />}
              />
              <Route
                path="/wallet/createInvoice/:merchantName?"
                element={<Wrapper component={<WalletCreateInvoice />} />}
              />
              {/* //TODO - Remove comment when enabling feature (1/3) */}
              {/* <Route
                path="/branches"
                element={<Wrapper component={<BranchesIndex />} />}
              /> */}
            </Route>
            <Route path="*" element={<Navigate to="/login" replace />} />
            <Route
              path="/error/:code"
              element={<Wrapper component={<ErrorPage />} />}
            />
            <Route
              path="/order-tracking/:id"
              element={<Wrapper component={<OrderTracking />} />}
            />
          </Routes>
        </ThemeProvider>
      </I18nextProvider>
    </div>
  );
};
export default App;
