import { useEffect, useRef, useState } from "react";
import {
  CircularProgress,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ItemHit from "./ItemHit";
import { useDispatch, useSelector } from "react-redux";
import { putCartItems, getCartId } from "../../../app/features/cart/cart";
import { useInfiniteHits, useInstantSearch } from "react-instantsearch";
import { useTranslation } from "react-i18next";
import { setHomeItemsTableScrollDirection } from "../../../app/features/interactiveUserInterface/interactiveUserInterface";
import { useGoogleAnalytics } from "../../../utils/api/googleAnalytics";

const CustomInfiniteHits = () => {
  //SECTION - algolia related
  const { items, showMore, isLastPage } = useInfiniteHits({});

  const { status } = useInstantSearch();
  //SECTION - general
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const { logAddToCart } = useGoogleAnalytics();

  //SECTION - useRefs
  const timeoutRef = useRef(null);
  const stackRef = useRef(null);
  const prevScrollPos = useRef(0);

  //SECTION - useState
  const [allItems, setAllItems] = useState([]);

  //SECTION - useSelector
  const cartId = useSelector(getCartId);

  //SECTION - useEffect
  useEffect(() => {
    if (allItems.length === 0) return;

    const debouncedUpdateCart = () => {
      const data = { items: allItems, cartId: cartId };
      dispatch(putCartItems(data)).then((res) => {
        if (res.type === "cart/putCartItems/fulfilled") {
          //Log event in Google Analytics
          logAddToCart(res.payload.data.saleOrderItems);
        }
      });
    };

    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(debouncedUpdateCart, 1000);

    return () => clearTimeout(timeoutRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allItems, cartId, dispatch]);

  //SECTION - scroll detection
  useEffect(() => {
    const stackEl = stackRef.current;

    // Check if the element exists before attaching the listener
    if (!stackEl) return;

    const handleScroll = () => {
      const currentScrollPos = stackEl.scrollTop;
      if (prevScrollPos.current < currentScrollPos) {
        dispatch(setHomeItemsTableScrollDirection("down"));
      } else if (prevScrollPos.current > currentScrollPos) {
        dispatch(setHomeItemsTableScrollDirection("up"));
      }
      prevScrollPos.current = currentScrollPos;
    };

    stackEl.addEventListener("scroll", handleScroll);

    return () => {
      stackEl.removeEventListener("scroll", handleScroll);
    };
  }, [dispatch]);

  return (
    <>
      {status === "loading" ? (
        <Stack
          direction={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"600px"}
        >
          <CircularProgress size={150} color="secondary" />
        </Stack>
      ) : (
        <Stack
          paddingBottom={"2rem"}
          gap={"0.5rem"}
          paddingX={isMobileScreen ? "0.2rem" : "1rem"}
          ref={stackRef}
          sx={{
            maxHeight: "80vh",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          {items.map((hit) => {
            return (
              <ItemHit
                hit={hit}
                key={hit.id}
                setAllItems={setAllItems}
                //NOTE - This level is used for item replacements
                hierarchyLevel={"one"}
              />
            );
          })}
          {!isLastPage && (
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              marginTop={"1rem"}
            >
              <Typography
                onClick={() => showMore()}
                fontWeight={theme.typography.bold}
                color={theme.palette.primary.main}
                sx={{
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                fontSize={{ xs: theme.spacing(2.5) }}
              >
                {t("home.showMore")}
              </Typography>
            </Stack>
          )}
        </Stack>
      )}
    </>
  );
};

export default CustomInfiniteHits;
