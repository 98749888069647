import { Grid2, useMediaQuery, useTheme } from "@mui/material";
import CustomHits from "./CustomHits";
import CustomHierarchicalMenu from "./CustomHierarchicalMenu";
import { useTranslation } from "react-i18next";

const HomeTable = ({ triggerFilterBrands, setTriggerFilterBrands }) => {
  //SECTION - general
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("semiLg"));
  const { i18n } = useTranslation();

  return (
    <Grid2 container my={"1rem"}>
      <Grid2
        container
        size={3.4}
        sx={{
          display: isSmallScreen && "none",
          paddingRight: i18n.language === "ar" ? "2rem" : "0rem",
          paddingLeft: i18n.language === "en" ? "2rem" : "0rem",
          height: "80vh",
          overflowY: !isSmallScreen && "scroll",
          overflowX: !isSmallScreen && "hidden",
        }}
      >
        <CustomHierarchicalMenu
          attributes={["categories.lvl0", "categories.lvl1"]}
          sortBy={["count:desc"]}
          limit={50}
          triggerFilterBrands={triggerFilterBrands}
          setTriggerFilterBrands={setTriggerFilterBrands}
        />
      </Grid2>
      <Grid2 size={isSmallScreen ? 12 : 8.6}>
        <CustomHits />
      </Grid2>
    </Grid2>
  );
};

export default HomeTable;
