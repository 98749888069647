import { useTheme } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import React from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import validation from "../../utils/Helpers/validation";
import { Typography } from "@mui/material";

const StyledDatePicker = ({
  value,
  onChange,
  title,
  height = "40px",
  placeholder,
}) => {
  //SECTION - general
  const theme = useTheme();

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Typography
        variant="body2"
        color={theme.palette.gray.g600}
        fontWeight="bold"
        paddingBottom="0.25rem"
      >
        {title}
      </Typography>
      <DatePicker
        dir={"ltr"}
        value={validation.isEmpty(value) ? moment() : value}
        onChange={onChange}
        label={placeholder}
        slotProps={{
          textField: {
            sx: {
              width: "100%",
              backgroundColor: "white",
              borderRadius: "10px",
              height: height,
              padding: "0",
              "& .MuiOutlinedInput-root": {
                height: "100%",
                "& fieldset": {
                  borderRadius: "10px",
                },
                "& .MuiInputBase-input": {
                  padding: "10px 14px",
                  height: "100%",
                  boxSizing: "border-box",
                },
                "&.Mui-focused fieldset": {
                  borderColor: theme.palette.primary.main,
                },
              },
            },
          },
          label: {
            sx: {
              "& .MuiFormLabel-root, & .MuiInputLabel-root, & .MuiInputLabel-formControl, & .MuiInputLabel-animated, & .MuiInputLabel-shrink, & .MuiInputLabel-sizeMedium, & .MuiInputLabel-outlined, & .MuiFormLabel-colorPrimary, & .MuiFormLabel-filled, & .css-1gyxcpg-MuiFormLabel-root-MuiInputLabel-root":
                {
                  marginBottom: "1rem",
                },
            },
          },
          popper: {
            sx: {
              "& .MuiPickersDay-root": {
                color: theme.palette.text.primary,
              },
              "& .MuiPickersDay-root.Mui-selected": {
                backgroundColor: theme.palette.primary.main,
                color: "white",
                borderRadius: "50%",
                "&:hover": {
                  backgroundColor: theme.palette.primary.dark,
                },
              },
              "& .MuiPickersDay-root.Mui-dayToday": {
                border: `2px solid ${theme.palette.primary.main}`,
                borderRadius: "50%",
              },
              "& .MuiPickersDay-root.Mui-dayToday.Mui-selected": {
                backgroundColor: theme.palette.primary.main,
                color: "white",
              },
            },
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default StyledDatePicker;
