// src/hooks/useFilterConfig.js
import { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { MenuItem, useMediaQuery, useTheme } from "@mui/material";
import { IoSearchOutline } from "react-icons/io5";
import { FaFileExcel, FaFilePdf } from "react-icons/fa6";
import { FileDownload } from "@mui/icons-material";
import {
  filterQueryDocuments,
  filterQuerySettlements,
  filterQueryStatuses,
  filterQueryTypes,
} from "../utils/constants";
import { notifyWithSuccess } from "../../../app/features/error/error";
import {
  getClientBNPLDocuments,
  getClientBNPLOrders,
  getClientBNPLTransactions,
  getSettlementData,
  issuePaymentGatewayOrdersStatement,
  issuePaymentGatewayStatementExcel,
  issuePaymentGatewayStatementPDF,
} from "../../../app/features/wallet/wallet";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { IoIosSearch } from "react-icons/io";

export const useFilterConfig = (tabIndex) => {
  //SECTION - general
  const { t } = useTranslation();
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();

  //SECTION - useSelector
  const issuePaymentGatewayOrdersStatementState = useSelector(
    (state) => state.walletReducer.issuePaymentGatewayOrdersStatementState
  );
  const issuePaymentGatewayStatementState = useSelector(
    (state) => state.walletReducer.issuePaymentGatewayStatementState
  );
  const issuePaymentGatewayStatementExcelState = useSelector(
    (state) => state.walletReducer.issuePaymentGatewayStatementExcelState
  );
  const validClientRequestStatus = useSelector(
    (state) => state.walletReducer.validClientStatus
  );
  const getSettlementDataStatus = useSelector(
    (state) => state.walletReducer.getSettlementDataStatus
  );
  const downloadDocumentStatus = useSelector(
    (state) => state.walletReducer.downloadDocumentStatus
  );
  const companyBranchId = useSelector(
    (state) => state.walletReducer.companyBranchId
  );

  //SECTION - useState
  const [tabStates, setTabStates] = useState({
    0: {
      searchQuery: "",
      filterQueryId: 1,
      durationFrom: moment(),
      durationTo: moment(),
      pagination: 1,
    },
    1: {
      searchQuery: "",
      filterQueryId: 1,
      durationFrom: moment(),
      durationTo: moment(),
      pagination: 1,
    },
    2: {
      searchQuery: "",
      filterQueryId: 1,
      durationFrom: moment(),
      durationTo: moment(),
      pagination: 1,
    },
    3: {
      filterQueryId: 1,
      durationFrom: moment(),
      durationTo: moment(),
      pagination: 1,
      includeDate: false,
    },
    // Add more tab states as needed
  });

  // SECTION - Fetching functions
  const fetchOrders = useCallback(
    (pagination, searchQuery, filterQuery) => {
      dispatch(
        getClientBNPLOrders({
          pagination: pagination ? pagination : tabStates[0]?.pagination,
          searchQuery: searchQuery ? searchQuery : tabStates[0]?.searchQuery,
          filterQuery: filterQuery
            ? filterQuery
            : filterQueryStatuses.find(
                (queryStatus) => queryStatus.id === tabStates[0]?.filterQueryId
              )?.label ?? "",
          company_branch_id: companyBranchId,
        })
      );
    },
    [dispatch, tabStates, companyBranchId]
  );
  const fetchTransactions = useCallback(
    (pagination, searchQuery, filterQuery) => {
      dispatch(
        getClientBNPLTransactions({
          pagination: pagination ? pagination : tabStates[1]?.pagination,
          searchQuery: searchQuery ? searchQuery : tabStates[1]?.searchQuery,
          filterQuery: filterQuery
            ? filterQuery
            : filterQueryTypes.find(
                (queryType) => queryType.id === tabStates[1]?.filterQueryId
              )?.label ?? "",
          company_branch_id: companyBranchId,
        })
      );
    },
    [dispatch, tabStates, companyBranchId]
  );

  const fetchSettlements = useCallback(
    (pagination, searchQuery, filterQuery) => {
      dispatch(
        getSettlementData({
          pagination: pagination ? pagination : tabStates[2]?.pagination,
          searchQuery: searchQuery ? searchQuery : tabStates[2]?.searchQuery,
          filterQuery: filterQuery
            ? filterQuery
            : filterQuerySettlements.find(
                (queryType) => queryType.id === tabStates[2]?.filterQueryId
              )?.label ?? "",
          company_branch_id: companyBranchId,
        })
      );
    },
    [dispatch, tabStates, companyBranchId]
  );
  const fetchDocuments = useCallback(
    (pagination, filterQuery, includeDate) => {
      dispatch(
        getClientBNPLDocuments({
          pagination: pagination ? pagination : tabStates[3]?.pagination,
          filterQuery: filterQuery
            ? filterQuery
            : filterQueryDocuments.find(
                (queryType) => queryType.id === tabStates[3]?.filterQueryId
              )?.label ?? "",
          ...((includeDate || tabStates[3]?.includeDate) && {
            date_from: tabStates[3]?.durationFrom,
            date_to: tabStates[3]?.durationTo,
          }),
          company_branch_id: companyBranchId,
        })
      );
    },
    [dispatch, tabStates, companyBranchId]
  );

  //SECTION - useEffect
  useEffect(() => {
    if (validClientRequestStatus === "succeeded") {
      if (tabIndex === 0) {
        fetchOrders();
      } else if (tabIndex === 1) {
        fetchTransactions();
      } else if (tabIndex === 2) {
        fetchSettlements();
      } else if (tabIndex === 3) {
        fetchDocuments();
      }
    }
  }, [
    validClientRequestStatus,
    tabIndex,
    fetchOrders,
    fetchSettlements,
    fetchTransactions,
    fetchDocuments,
  ]);
  //SECTION - functions
  const handleStateChange = (tabIndex, field, value) => {
    setTabStates((prevState) => ({
      ...prevState,
      [tabIndex]: {
        ...prevState[tabIndex],
        [field]: value,
      },
    }));
  };
  const filterConfigs = {
    0: {
      filterComponents: [
        {
          type: "input",
          props: {
            icon: <IoSearchOutline />,
            placeholder: t("wallet.search"),
            title: t("wallet.searchTitle"),
            value: tabStates[0]?.searchQuery,
            onChange: (e) =>
              handleStateChange(tabIndex, "searchQuery", e.target.value),
            onKeyPress: (event) => {
              if (event.key === "Enter") {
                fetchOrders();
              }
            },
          },
        },
        {
          type: "select",
          props: {
            selectItems: filterQueryStatuses.map(({ id, label }) => (
              <MenuItem key={id} value={id}>
                {t(`wallet.${label}`)}
              </MenuItem>
            )),
            title: t("wallet.status"),
            value: tabStates[0]?.filterQueryId,
            onChange: (e) => {
              handleStateChange(tabIndex, "filterQueryId", e.target.value);
              fetchOrders(
                null,
                null,
                filterQueryStatuses.find(
                  (queryStatus) => queryStatus.id === e.target.value
                )?.label
              );
            },
          },
        },
        {
          type: "date",
          props: {
            value: tabStates[0]?.durationFrom,
            onChange: (e) => handleStateChange(tabIndex, "durationFrom", e),
            title: t("wallet.startAt"),
          },
        },
        {
          type: "date",
          props: {
            value: tabStates[0]?.durationTo,
            onChange: (e) => handleStateChange(tabIndex, "durationTo", e),
            title: t("wallet.endAt"),
          },
        },
        {
          type: "button",
          props: {
            content:
              issuePaymentGatewayOrdersStatementState === "loading"
                ? t("wallet.loading")
                : t("wallet.createSessionsReport"),
            color: "secondary",
            endIcon: <FileDownload />,
            disabled: issuePaymentGatewayOrdersStatementState === "loading",
            height: "40px",
            fullWidth: isMedium ? true : false,
            onClick: () =>
              dispatch(
                issuePaymentGatewayOrdersStatement({
                  duration_from: moment(tabStates[0]?.durationFrom).format(
                    "YYYY-MM-DD"
                  ),
                  duration_to: moment(tabStates[0]?.durationTo).format(
                    "YYYY-MM-DD"
                  ),
                  company_branch_id: companyBranchId,
                })
              ).then((payload) => {
                if (
                  payload.type ===
                  "wallet/issuePaymentGatewayOrdersStatement/fulfilled"
                ) {
                  const url = window.URL.createObjectURL(payload.payload);
                  const a = document.createElement("a");
                  a.href = url;
                  a.download = `BNPL_REQUESTS_${moment(
                    tabStates[0]?.durationFrom
                  ).format("YYYY-MM-DD")}_${moment(
                    tabStates[0]?.durationTo
                  ).format("YYYY-MM-DD")}.xlsx`;
                  document.body.appendChild(a);
                  a.click();
                  document.body.removeChild(a);
                  window.URL.revokeObjectURL(url);
                  dispatch(
                    notifyWithSuccess({
                      message: t("wallet.reportCreatedSuccessfully"),
                      type: "success",
                    })
                  );
                }
              }),
            borderRadius: "10px",
          },
        },
      ],
    },
    1: {
      filterComponents: [
        {
          type: "input",
          props: {
            icon: <IoSearchOutline />,
            placeholder: t("wallet.search"),
            title: t("wallet.orderID"),
            value: tabStates[1]?.searchQuery,
            onChange: (e) =>
              handleStateChange(tabIndex, "searchQuery", e.target.value),
            onKeyPress: (event) => {
              if (event.key === "Enter") {
                fetchTransactions();
              }
            },
          },
          gridProps: { xs: 12, md: 3, xl: 2.15 },
        },
        {
          type: "select",
          props: {
            selectItems: filterQueryTypes.map(({ id, label }) => (
              <MenuItem key={id} value={id}>
                {t(`wallet.${label}`.toLowerCase())}
              </MenuItem>
            )),
            title: t("wallet.type"),
            value: tabStates[1]?.filterQueryId,
            onChange: (e) => {
              handleStateChange(tabIndex, "filterQueryId", e.target.value);
              fetchTransactions(
                null,
                null,
                filterQueryTypes.find(
                  (queryStatus) => queryStatus.id === e.target.value
                )?.label
              );
            },
          },
          gridProps: { xs: 12, md: 3, xl: 2.15 },
        },
        {
          type: "date",
          props: {
            value: tabStates[1]?.durationFrom,
            onChange: (e) => handleStateChange(tabIndex, "durationFrom", e),

            title: t("wallet.startAt"),
          },
          gridProps: { xs: 12, md: 3, xl: 2.1 },
        },
        {
          type: "date",
          props: {
            value: tabStates[1]?.durationTo,
            onChange: (e) => handleStateChange(tabIndex, "durationTo", e),
            title: t("wallet.endAt"),
          },
          gridProps: { xs: 12, md: 3, xl: 2.1 },
        },
        {
          type: "button",
          props: {
            content:
              issuePaymentGatewayStatementState === "loading"
                ? t("wallet.loading")
                : t("wallet.createStatement"),
            color: "secondary",
            endIcon: <FaFilePdf />,
            fullWidth: isMedium || isSmall ? true : false,
            disabled: issuePaymentGatewayStatementState === "loading",
            height: "40px",
            onClick: () =>
              dispatch(
                issuePaymentGatewayStatementPDF({
                  duration_from: moment(tabStates[1]?.durationFrom).format(
                    "YYYY-MM-DD"
                  ),
                  duration_to: moment(tabStates[1]?.durationTo).format(
                    "YYYY-MM-DD"
                  ),
                  company_branch_id: companyBranchId,
                })
              ).then((payload) => {
                if (
                  payload.type ===
                  "wallet/issuePaymentGatewayStatement/fulfilled"
                ) {
                  dispatch(
                    notifyWithSuccess({
                      message: t("wallet.reportCreatedSuccessfully"),
                      type: "success",
                    })
                  );
                  if (payload?.payload?.content?.url) {
                    window.open(payload.payload.content.url, "_blank");
                  }
                }
              }),
            borderRadius: "10px",
          },
          gridProps: { xs: 12, sm: 6, md: 3, xl: 1.75 },
        },
        {
          type: "button",
          props: {
            content:
              issuePaymentGatewayStatementExcelState === "loading"
                ? t("wallet.loading")
                : t("wallet.createStatementCsv"),
            color: "secondary",
            endIcon: <FaFileExcel />,
            fullWidth: isMedium || isSmall ? true : false,
            disabled: issuePaymentGatewayStatementExcelState === "loading",
            height: "40px",
            onClick: () =>
              dispatch(
                issuePaymentGatewayStatementExcel({
                  duration_from: moment(tabStates[1]?.durationFrom).format(
                    "YYYY-MM-DD"
                  ),
                  duration_to: moment(tabStates[1]?.durationTo).format(
                    "YYYY-MM-DD"
                  ),
                  company_branch_id: companyBranchId,
                })
              ).then((payload) => {
                if (
                  payload.type ===
                  "wallet/issuePaymentGatewayStatementExcel/fulfilled"
                ) {
                  const url = window.URL.createObjectURL(payload.payload);
                  const a = document.createElement("a");
                  a.href = url;
                  a.download = `BNPL_TRANSACTIONS_${moment(
                    tabStates[1]?.durationFrom
                  ).format("YYYY-MM-DD")}_${moment(
                    tabStates[1]?.durationTo
                  ).format("YYYY-MM-DD")}.xlsx`;
                  document.body.appendChild(a);
                  a.click();
                  document.body.removeChild(a);
                  window.URL.revokeObjectURL(url);
                  dispatch(
                    notifyWithSuccess({
                      message: t("wallet.reportCreatedSuccessfully"),
                      type: "success",
                    })
                  );
                }
              }),
            borderRadius: "10px",
          },
          gridProps: { xs: 12, sm: 6, md: 3, xl: 1.75 },
        },
      ],
    },
    2: {
      filterComponents: [
        {
          type: "input",
          props: {
            icon: <IoSearchOutline />,
            placeholder: t("wallet.search"),
            title: t("wallet.searchTitle"),
            value: tabStates[2]?.searchQuery,
            onChange: (e) =>
              handleStateChange(tabIndex, "searchQuery", e.target.value),
            onKeyPress: (event) => {
              if (event.key === "Enter") {
                fetchSettlements();
              }
            },
          },
        },
        {
          type: "select",
          props: {
            selectItems: filterQuerySettlements.map(
              ({ id, label, shownLabel }) => (
                <MenuItem key={id} value={id}>
                  {t(`wallet.${shownLabel ? shownLabel : label}`)}
                </MenuItem>
              )
            ),
            title: t("wallet.settlementStatus"),
            value: tabStates[2]?.filterQueryId,
            onChange: (e) => {
              handleStateChange(tabIndex, "filterQueryId", e.target.value);
              fetchSettlements(
                null,
                null,
                filterQuerySettlements.find(
                  (queryStatus) => queryStatus.id === e.target.value
                )?.label
              );
            },
          },
        },
        {
          type: "date",
          props: {
            value: tabStates[2]?.durationFrom,
            onChange: (e) => handleStateChange(tabIndex, "durationFrom", e),
            title: t("wallet.startAt"),
          },
        },
        {
          type: "date",
          props: {
            value: tabStates[2]?.durationTo,
            onChange: (e) => handleStateChange(tabIndex, "durationTo", e),
            title: t("wallet.endAt"),
          },
        },
        {
          type: "button",
          props: {
            content:
              getSettlementDataStatus === "loading"
                ? t("wallet.loading")
                : t("wallet.createSettlementReport"),
            color: "secondary",
            endIcon: <FileDownload />,
            disabled: getSettlementDataStatus === "loading",
            height: "40px",
            fullWidth: isMedium ? true : false,
            onClick: () =>
              dispatch(
                getSettlementData({
                  date_from: moment(tabStates[2]?.durationFrom).format(
                    "YYYY-MM-DD"
                  ),
                  date_to: moment(tabStates[2]?.durationTo).format(
                    "YYYY-MM-DD"
                  ),
                  response_type: "csv",
                  company_branch_id: companyBranchId,
                })
              ).then((payload) => {
                if (payload.type === "wallet/getSettlementData/fulfilled") {
                  if (payload.payload.url) {
                    const link = document.createElement("a");
                    link.href = payload.payload.url;
                    link.download = `BNPL_SETTLEMENTS_${moment(
                      tabStates[2]?.durationFrom
                    ).format("YYYY-MM-DD")}_${moment(
                      tabStates[2]?.durationTo
                    ).format("YYYY-MM-DD")}.csv`;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  }
                }
              }),
            borderRadius: "10px",
          },
        },
      ],
    },
    3: {
      filterComponents: [
        {
          type: "select",
          props: {
            selectItems: filterQueryDocuments.map(({ id, label }) => (
              <MenuItem key={id} value={id}>
                {t(`wallet.documentsStatuses.${label}`)}
              </MenuItem>
            )),
            title: t("wallet.fileType"),
            value: tabStates[3]?.filterQueryId,
            onChange: (e) => {
              handleStateChange(tabIndex, "filterQueryId", e.target.value);
              fetchDocuments(
                null,
                filterQueryDocuments.find(
                  (queryStatus) => queryStatus.id === e.target.value
                )?.label
              );
            },
          },
        },
        {
          type: "date",
          props: {
            value: tabStates[3]?.durationFrom,
            onChange: (e) => {
              handleStateChange(tabIndex, "durationFrom", e);
            },
            title: t("wallet.startAt"),
          },
        },
        {
          type: "date",
          props: {
            value: tabStates[3]?.durationTo,
            onChange: (e) => {
              handleStateChange(tabIndex, "durationTo", e);
            },
            title: t("wallet.endAt"),
          },
        },
        {
          type: "button",
          props: {
            content:
              downloadDocumentStatus === "loading"
                ? t("wallet.loading")
                : t("wallet.searchTitle"),
            color: "secondary",
            endIcon: <IoIosSearch />,
            disabled: downloadDocumentStatus === "loading",
            height: "40px",
            fullWidth: isMedium ? true : false,
            onClick: () => {
              handleStateChange(3, "includeDate", true);
              fetchDocuments(null, null, true);
            },
            borderRadius: "10px",
          },
        },
      ],
    },
    // Add more tabIndex configurations as needed
  };

  return {
    fetchOrders,
    fetchTransactions,
    fetchSettlements,
    fetchDocuments,
    filterConfigs: filterConfigs[tabIndex] || { filterComponents: [] },
    tabStates,
    handleStateChange,
  };
};
