import { useTheme } from "@emotion/react";
import { Box, CircularProgress, Grid2, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import IsEmpty from "../../utils/validation/IsEmpty";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import MerchantCard from "./components/cards/MerchantCard";
import CreateInvoiceForm from "./components/CreateInvoiceForm";
import TermsAndConditions from "./components/TermsAndConditions";
import useCheckClientValidity from "./hooks/useCheckClientValidity";
import {
  getClientWallet,
  getCompanyBranchId,
  getMerchantProviders,
} from "../../app/features/wallet/wallet";
import { notifyWithError } from "../../app/features/error/error";
import { formatRate } from "./utils/functions";
import ButtonComp from "../../components/common/ButtonComp";

const WalletCreateInvoice = () => {
  ///SECTION - general
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { merchantName: paramsMerchantName } = useParams();
  const { t, i18n } = useTranslation();
  const termsAndConditionsRef = useRef(null);

  //SECTION - custom hooks
  const { fetchAttemptsLoading, fetchAttempts } = useCheckClientValidity();

  //SECTION - useState
  const [selectedMerchantName, setSelectedMerchantName] = useState("");
  const [selectedMerchantObj, setSelectedMerchantObj] = useState();
  const [commissionRate, setCommissionRate] = useState(6);
  const [refundRate, setRefundRate] = useState(2);

  //SECTION - useSelector
  const merchants = useSelector((state) => state.walletReducer.merchants);

  const getWatchtowerStatus = useSelector(
    (state) => state.walletReducer.getWatchtowerTokenStatus
  );
  const userFirstName = useSelector(
    (state) => state.walletReducer.repFirstName
  );
  const userLastName = useSelector((state) => state.walletReducer.repLastName);
  const clientValidity = useSelector(
    (state) => state.walletReducer.validClient
  );
  const validClientRequestStatus = useSelector(
    (state) => state.walletReducer.validClientStatus
  );
  const clientWallets = useSelector(
    (state) => state.walletReducer.clientWallets
  );
  const getWalletStatus = useSelector(
    (state) => state.walletReducer.getClientWalletStatus
  );

  //SECTION - useEffect
  useEffect(() => {
    if (validClientRequestStatus === "succeeded") {
      dispatch(getMerchantProviders());
      dispatch(getClientWallet());
      dispatch(getCompanyBranchId()).then((res) => {
        if (Array.isArray(res.payload?.company_branch_id)) {
          dispatch(
            notifyWithError({
              message: t("wallet.refreshPage"),
              type: "Error",
            })
          );
        }
      });
    }
  }, [fetchAttempts, validClientRequestStatus, dispatch, t]);

  useEffect(() => {
    if (merchants) {
      //if no params were passed, then select the default wallet.
      setSelectedMerchantName(
        paramsMerchantName ? paramsMerchantName : merchants[0].name_en
      );
      navigate(
        `/wallet/createInvoice/${
          paramsMerchantName ? paramsMerchantName : merchants[0].name_en
        }`
      );
      //store the selected merchant object
      setSelectedMerchantObj(
        merchants.find((merchant) => merchant.name_en === paramsMerchantName)
      );
    }
  }, [paramsMerchantName, merchants, navigate]);

  useEffect(() => {
    if (selectedMerchantObj && clientWallets) {
      const wallet = clientWallets.find(
        (wallet) => wallet.payment_gateway_type?.id === selectedMerchantObj.id
      );

      const comRate = wallet?.commission_rate * 100;
      const refRate = wallet?.refund_rate * 100;

      if (isNaN(formatRate(comRate)) || IsEmpty(formatRate(comRate))) {
        setCommissionRate(6); // Default value if rate is not available
      } else {
        setCommissionRate(parseFloat(formatRate(comRate)));
      }
      if (isNaN(formatRate(refRate)) || IsEmpty(formatRate(refRate))) {
        setRefundRate(2); // Default value if rate is not available
      } else {
        setRefundRate(parseFloat(formatRate(refRate)));
      }
    }
  }, [selectedMerchantObj, clientWallets]);

  //SECTION - FUNCTIONS
  const handleSelectedMerchant = (merchant) => {
    setSelectedMerchantName(merchant?.name_en);
    navigate(`/wallet/createInvoice/${merchant?.name_en}`);
  };

  return (
    <>
      {fetchAttemptsLoading ||
      validClientRequestStatus === "loading" ||
      getWatchtowerStatus === "loading" ||
      getWalletStatus !== "succeeded" ||
      !selectedMerchantObj ? (
        <Grid2
          size={{ xs: 12 }}
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ height: "80vh" }}
        >
          <CircularProgress size={100} />
        </Grid2>
      ) : clientValidity ? (
        <Stack
          p={{ xs: theme.spacing(2), sm: theme.spacing(4) }}
          gap={theme.spacing(3)}
          sx={{ maxWidth: "1400px", marginX: "auto" }}
          padding={{ xs: "0.5rem", md: "2rem" }}
        >
          <Typography
            fontSize={"1.5rem"}
            fontWeight={theme.typography.bold}
            sx={{ margin: 0, px: theme.spacing(1) }}
          >
            {t("wallet.welcome", {
              first_name: userFirstName ?? "",
              last_name: userLastName ?? "",
            })}
          </Typography>
          <Grid2
            container
            alignItems={"center"}
            justifyContent={"space-between"}
            backgroundColor={"none"}
          >
            <Grid2
              container
              xs={12}
              semiMd
              gap={theme.spacing(2)}
              wrap="nowrap"
              sx={{
                overflowX: "scroll",
                alignItems: "center",
                padding: theme.spacing(1),
                scrollbarWidth: "none",
                msOverflowStyle: "none",
                "&::-webkit-scrollbar": {
                  width: 0,
                },
              }}
            >
              {merchants?.map((merchant) => {
                return (
                  merchant.is_active && (
                    <MerchantCard
                      merchant={merchant}
                      isSelected={selectedMerchantName === merchant.name_en}
                      handleClick={() => handleSelectedMerchant(merchant)}
                    />
                  )
                );
              })}
            </Grid2>
            <Grid2
              container
              size={{ xs: 12, md: 2.5 }}
              justifyContent={"flex-end"}
            >
              <ButtonComp
                content={
                  <Typography>{t("wallet.transactionDetails")}</Typography>
                }
                color="secondary"
                borderRadius={"10px"}
                onClick={() => {
                  navigate("/wallet");
                }}
                paddingX={"1rem"}
                height={"40px"}
                endIcon={
                  i18n.dir() === "rtl" ? (
                    <MdArrowBackIos />
                  ) : (
                    <MdArrowForwardIos />
                  )
                }
              />
            </Grid2>
          </Grid2>
          <Box
            width={"100%"}
            sx={{
              backgroundColor: theme.palette.gray.g100,
              padding: theme.spacing(2),
              borderRadius: theme.borderRadius.r15,
            }}
          >
            <CreateInvoiceForm
              selectedMerchantName={selectedMerchantName}
              selectedMerchantObj={selectedMerchantObj}
            />
          </Box>
          <TermsAndConditions
            termsAndConditionsRef={termsAndConditionsRef}
            commissionRate={commissionRate}
            refundRate={refundRate}
          />
        </Stack>
      ) : (
        <Grid2
          size={{ xs: 12 }}
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          textAlign={"center"}
          height={"80vh"}
          padding={theme.spacing(2)}
        >
          <Typography
            fontWeight={theme.typography.bold}
            fontSize={{
              xs: theme.spacing(4),
              md: theme.spacing(8),
            }}
            color={theme.palette.primary.main}
          >
            {t("wallet.notRegisteredClient")}
          </Typography>
        </Grid2>
      )}
    </>
  );
};

export default WalletCreateInvoice;
