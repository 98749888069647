import { useTheme } from "@emotion/react";
import {
  Grid2,
  Typography,
  Stack,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiFillExclamationCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BannerOffer from "../Home/Banner/BannerOffer";
import moment from "moment";
import useCheckClientValidity from "./hooks/useCheckClientValidity";
import useSetInstallmentWallets from "./hooks/useSetInstallmentWallets";
import WalletsSlider from "./components/WalletsSlider";
import CustomTabs from "./components/CustomTabs";
import ShimmerSkeleton from "./components/ShimmerSkeleton";
import FilterSection from "./components/FilterSection";
import DataTable from "./components/table/DataTable";
import {
  getBNPLDocumentsTableColumns,
  getBNPLOrdersTableColumns,
  getBNPLSettlementTableColumns,
  getBNPLTransactionsTableColumns,
  offer_object,
} from "./utils/constants";
import { useFilterConfig } from "./hooks/useFilterConfig";
import ButtonComp from "../../components/common/ButtonComp";
import { getCompanyBranchId } from "../../app/features/wallet/wallet";

const Wallet = () => {
  //SECTION - general
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //SECTION - useState
  const [tabIndex, setTabIndex] = useState(0);

  //SECTION - custom hooks
  const { fetchAttemptsLoading } = useCheckClientValidity();
  const { balanceCardWalletObjects } = useSetInstallmentWallets();
  const {
    fetchOrders,
    fetchTransactions,
    fetchSettlements,
    filterConfigs,
    tabStates,
    handleStateChange,
    fetchDocuments,
  } = useFilterConfig(tabIndex);

  //SECTION - useSelector
  const clientValidity = useSelector(
    (state) => state.walletReducer.validClient
  );
  const canViewWallet = useSelector(
    (state) => state.walletReducer.userCanViewWallet
  );
  const validClientRequestStatus = useSelector(
    (state) => state.walletReducer.validClientStatus
  );
  const getWatchtowerStatus = useSelector(
    (state) => state.walletReducer.getWatchtowerTokenStatus
  );
  const getWalletStatus = useSelector(
    (state) => state.walletReducer.getClientWalletStatus
  );
  const getAutobiaWalletStatus = useSelector(
    (state) => state.walletReducer.getClientAutobiaWalletStatus
  );
  const userFirstName = useSelector(
    (state) => state.walletReducer.repFirstName
  );
  const userLastName = useSelector((state) => state.walletReducer.repLastName);

  const clientBNPLOrders = useSelector(
    (state) => state.walletReducer.clientBNPLOrders
  );
  const clientBNPLTransactions = useSelector(
    (state) => state.walletReducer.clientBNPLTransactions
  );
  const settlementData = useSelector(
    (state) => state.walletReducer.settlementData
  );
  const clientBNPLTransactionsStatus = useSelector(
    (state) => state.walletReducer.getClientBNPLTransactionsStatus
  );
  const clientBNPLOrdersStatus = useSelector(
    (state) => state.walletReducer.getClientBNPLOrdersStatus
  );
  const getSettlementDataStatus = useSelector(
    (state) => state.walletReducer.getSettlementDataStatus
  );
  const documents = useSelector((state) => state.walletReducer.documents);
  const getClientBNPLDocumentsStatus = useSelector(
    (state) => state.walletReducer.getClientBNPLDocumentsStatus
  );
  const getCompanyBranchIdStatus = useSelector(
    (state) => state.walletReducer.getCompanyBranchIdStatus
  );
  const companyBranchId = useSelector(
    (state) => state.walletReducer.companyBranchId
  );
  //SECTION - useEffect
  useEffect(() => {
    dispatch(getCompanyBranchId());
  }, [dispatch]);

  return (
    <>
      {fetchAttemptsLoading ||
      [
        validClientRequestStatus,
        getWatchtowerStatus,
        getCompanyBranchIdStatus,
      ].includes("loading") ||
      !companyBranchId ? (
        <Grid2
          size={{ xs: 12 }}
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ height: "80vh" }}
        >
          <CircularProgress size={100} />
        </Grid2>
      ) : clientValidity ? (
        <Stack
          direction={"column"}
          container
          xs={12}
          sx={{ maxWidth: "1400px", marginX: "auto" }}
          gap={theme.spacing(2)}
          padding={{ xs: "0.5rem", md: "2rem" }}
        >
          <Typography
            fontSize={"1.5rem"}
            fontWeight={theme.typography.bold}
            sx={{ margin: 0, px: theme.spacing(2) }}
          >
            {t("wallet.welcome", {
              first_name: userFirstName ?? "",
              last_name: userLastName ?? "",
            })}
          </Typography>
          <Grid2
            container
            size={{ xs: 12 }}
            justifyContent={"space-between"}
            sx={{ marginBottom: theme.spacing(2), px: theme.spacing(2) }}
          >
            <Stack direction={"row"} gap={theme.spacing(1)} alignItems="center">
              <Typography
                fontWeight={theme.typography.bold}
                fontSize={"1.15rem"}
              >
                {t("wallet.installmentService")}
              </Typography>
              <Tooltip
                title={<Typography>{t("wallet.walletInformation")}</Typography>}
              >
                <Stack sx={{ cursor: "pointer" }} justifyContent={"center"}>
                  <AiFillExclamationCircle
                    fontSize={"1.5rem"}
                    color={theme.palette.gray.g400}
                  />
                </Stack>
              </Tooltip>
            </Stack>
            <ButtonComp
              content={<Typography>{t("wallet.createInvoice")}</Typography>}
              color="secondary"
              borderRadius={"10px"}
              onClick={() => navigate(`/wallet/createInvoice/Tabby`)}
              paddingX={"1rem"}
              height={"40px"}
            />
          </Grid2>
          <>
            {canViewWallet && (
              <Grid2 container>
                {getWalletStatus === "loading" ||
                getAutobiaWalletStatus === "loading" ? (
                  <ShimmerSkeleton />
                ) : (
                  <div
                    style={{
                      width: "100%",
                      marginBottom: theme.spacing(2),
                    }}
                  >
                    <WalletsSlider
                      balanceCardWalletObjects={balanceCardWalletObjects}
                    />
                  </div>
                )}
              </Grid2>
            )}
            {moment(offer_object.expiration_at).isAfter(moment()) && (
              <Grid2 container justifyContent={"center"} flex={1}>
                <Grid2 item size={{ xs: 12 }} md={10}>
                  <BannerOffer key={offer_object.id} eachOffer={offer_object} />
                </Grid2>
              </Grid2>
            )}
            <Grid2
              container
              size={{ xs: 12 }}
              sx={{ borderBottom: `1px solid ${theme.palette.grey[500]}` }}
            >
              <CustomTabs tabIndex={tabIndex} setTabIndex={setTabIndex} />
            </Grid2>

            <Grid2
              container
              size={{ xs: 12 }}
              borderRadius={theme.borderRadius.r15}
              padding={"1rem"}
              sx={{
                backgroundColor: "white",
                // boxShadow: theme.shadows[3],
              }}
            >
              <FilterSection
                tabIndex={tabIndex}
                currentConfig={filterConfigs}
              />
            </Grid2>
            <Grid2 size={{ xs: 12 }} container marginY={"1rem"}>
              <DataTable
                columns={
                  tabIndex === 0
                    ? getBNPLOrdersTableColumns()
                    : tabIndex === 1
                    ? getBNPLTransactionsTableColumns()
                    : tabIndex === 2
                    ? getBNPLSettlementTableColumns()
                    : getBNPLDocumentsTableColumns()
                }
                items={
                  tabIndex === 0
                    ? clientBNPLOrders
                    : tabIndex === 1
                    ? clientBNPLTransactions
                    : tabIndex === 2
                    ? settlementData
                    : documents
                }
                isTransactions={tabIndex === 1 ? true : false}
                loading={
                  clientBNPLTransactionsStatus === "loading" ||
                  clientBNPLOrdersStatus === "loading" ||
                  getSettlementDataStatus === "loading" ||
                  getClientBNPLDocumentsStatus === "loading"
                }
                fetchOrders={fetchOrders}
                fetchTransactions={fetchTransactions}
                fetchSettlements={fetchSettlements}
                fetchDocuments={fetchDocuments}
                tabStates={tabStates}
                handleStateChange={handleStateChange}
                tabIndex={tabIndex}
              />
            </Grid2>
          </>
        </Stack>
      ) : (
        <Grid2
          size={{ xs: 12 }}
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          textAlign={"center"}
          height={"80vh"}
          padding={theme.spacing(2)}
        >
          <Typography
            fontWeight={theme.typography.bold}
            fontSize={{
              xs: theme.spacing(4),
              md: theme.spacing(8),
            }}
            color={theme.palette.primary.main}
          >
            {t("wallet.notRegisteredClient")}
          </Typography>
        </Grid2>
      )}
    </>
  );
};

export default Wallet;
