import { useHierarchicalMenu } from "react-instantsearch";
import { useTheme, List } from "@mui/material";
import { useSelector } from "react-redux";
import ListItem from "./ListItem";
import { useState, useEffect } from "react";

const CustomHierarchicalMenu = ({
  attributes,
  sortBy,
  limit,
  triggerFilterBrands,
  setTriggerFilterBrands,
}) => {
  //SECTION - general
  const { items, refine } = useHierarchicalMenu({
    attributes: attributes,
    limit: limit,
    sortBy: sortBy,
  });
  const theme = useTheme();

  //SECTION - useState
  const [selectedItem, setSelectedItem] = useState(null);

  //SECTION - useSelector
  const brands = useSelector((state) => state.brandReducer.brands);

  //SECTION - useEffect
  useEffect(() => {
    if (triggerFilterBrands && selectedItem) {
      setSelectedItem(null);
      refine(selectedItem);
    }
    setTriggerFilterBrands(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerFilterBrands, selectedItem]);

  //SECTION - functions
  const handleRefinement = (item) => {
    refine(item.value);
  };

  const getBrandIdByLabel = (label) => {
    const brand = brands.find((brand) => brand.name_en === label);
    return brand ? brand.id : 0; // Default to 0 if no matching brand is found
  };

  const sortHierarchicalItems = (items, brands) => {
    const brandIds = items.map((item) => getBrandIdByLabel(item.label));
    const counts = items.map((item) => item.count || 0);

    const maxBrandId = Math.max(...brandIds);
    const minBrandId = Math.min(...brandIds);
    const maxCount = Math.max(...counts);
    return items
      .map((item) => {
        const brandId = getBrandIdByLabel(item.label);
        const normalizedBrandId =
          (maxBrandId - brandId) / (maxBrandId - minBrandId || 1);
        const normalizedCount = (item.count || 0) / (maxCount || 1);
        const score = 0.2 * normalizedBrandId + 0.8 * normalizedCount;
        return {
          ...item,
          score, // Add score to each item for debugging (optional)
        };
      })
      .sort((a, b) => b.score - a.score) // Sort by score
      .map((item) => ({
        ...item,
        items: item.items ? sortHierarchicalItems(item.items) : [], // Recursively sort nested items
      }));
  };

  const sortedItems = sortHierarchicalItems(items);

  return (
    <List
      sx={{
        width: "100%",
        bgcolor: "background.paper",
        border: `2px solid ${theme.palette.gray.g200}`,
        borderRadius: theme.borderRadius.r15,
        padding: "0px",
      }}
      component="nav"
    >
      {sortedItems.map((item, index) => {
        return (
          <ListItem
            key={index}
            firstItem={index === 0}
            lastItem={index === items.length - 1}
            item={item}
            handleRefinement={handleRefinement}
            brands={brands}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
          />
        );
      })}
    </List>
  );
};

export default CustomHierarchicalMenu;
