import {
  Button,
  Typography,
  Modal,
  Grid,
  useTheme,
  Input,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import FindBrandLogo from "../../utils/data/FindBrandLogo";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import ValidateInputOnlyNum from "../../utils/validation/ValidateInputOnlyNum";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ButtonComp from "../../components/common/ButtonComp";
import {
  putCartItems,
  getCartId,
  getCartItems,
  getPutCartStatus,
  resetUpdateCartStatus,
} from "../../app/features/cart/cart";
import { useDispatch, useSelector } from "react-redux";
import {
  notifyWithError,
  notifyWithSuccess,
} from "../../app/features/error/error";
import { digitsArToEn } from "@persian-tools/persian-tools";
import { useGoogleAnalytics } from "../../utils/api/googleAnalytics";

const AddItemModal = ({ open, onClose, item, brands }) => {
  //SECTION - general
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("semiMd"));
  const { logAddToCart, logRemoveFromCart } = useGoogleAnalytics();

  //SECTION - useState
  const [quantity, setQuantity] = useState(0);
  const [currentItem, setItem] = useState({});
  const [exists, setExists] = useState(false);
  const [isButtonClicked, setButtonClicked] = useState(false);

  //SECTION - useSelector
  const cartId = useSelector(getCartId);
  const cartItems = useSelector(getCartItems);
  const putCartStatus = useSelector(getPutCartStatus);

  //SECTION - useSelector
  useEffect(() => {
    cartItems.forEach((eachItem) => {
      if (eachItem.id === item.id) {
        setQuantity(eachItem.items_detail.quantity);
        setItem({
          id: eachItem.id,
          quantity: eachItem.items_detail.quantity,
        });
        setExists(true);
      }
    });
  }, [cartItems, item.id]);
  useEffect(() => {
    if (isButtonClicked && putCartStatus === "succeeded") {
      onClose();
      dispatch(resetUpdateCartStatus());
      dispatch(
        notifyWithSuccess({
          message: t("cart.successfullyUpdated"),
          type: "success",
        })
      );
    } else if (isButtonClicked && putCartStatus === "failed") {
      dispatch(
        notifyWithError({
          message: t("cart.failedUpdated"),
          type: "error",
        })
      );
    }
  }, [isButtonClicked, putCartStatus, dispatch, onClose, t]);

  //SECTION - functions
  const handleSubmit = () => {
    if (exists) {
      dispatch(putCartItems({ items: [currentItem], cartId: cartId })).then(
        (res) => {
          if (res.type === "cart/putCartItems/fulfilled") {
            //Log event in Google Analytics
            logAddToCart(res.payload.data.saleOrderItems);
          }
        }
      );
    } else {
      //exists is used to not update the cart with a new item with 0 quantity, which will make unnecessary calls to the API
      if (quantity !== 0) {
        dispatch(putCartItems({ items: [currentItem], cartId: cartId })).then(
          (res) => {
            if (res.type === "cart/putCartItems/fulfilled") {
              //Log event in Google Analytics
              logAddToCart(res.payload.data.saleOrderItems);
            }
          }
        );
      } else {
        onClose();
      }
    }
    setButtonClicked(true);
  };
  const handleChange = (e) => {
    if (e.target.value === "") {
      setQuantity(0);
    } else if (isNaN(e.target.value)) {
      e.preventDefault();
    } else if (parseInt(digitsArToEn(e.target.value)) > 999999) {
      setQuantity((prev) => prev);
    } else {
      setQuantity(parseInt(digitsArToEn(e.target.value)));
      setItem({
        id: item.id,
        quantity: digitsArToEn(e.target.value),
      });
    }
  };
  return (
    <Modal open={open} onClose={onClose}>
      <Grid
        container
        direction={"column"}
        gap={theme.spacing(1)}
        justifyContent={"center"}
        alignItems={"center"}
        borderRadius={theme.borderRadius.r15}
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: isSmallScreen === true ? "350px" : "500px",
        }}
        bgcolor={theme.palette.gray.g100}
      >
        <Grid
          xs={12}
          paddingX={theme.spacing(4)}
          paddingTop={theme.spacing(2)}
          container
          direction={"row"}
        >
          <Grid
            xs={5}
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography color={theme.palette.gray.g400} fontSize={"1rem"}>
              {t("order.item")}
            </Typography>
          </Grid>
          <Grid xs={3}>
            <Typography color={theme.palette.gray.g400} fontSize={"1rem"}>
              {t("order.description")}
            </Typography>
          </Grid>
          <Grid
            xs={4}
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Typography color={theme.palette.gray.g400} fontSize={"1rem"}>
              {t("order.priceTableHeader")}
            </Typography>
          </Grid>
        </Grid>
        <Grid paddingX={theme.spacing(4)} xs={12} container direction={"row"}>
          <Grid
            xs={5}
            container
            direction={"row"}
            alignItems={"center"}
            justifyContent={"flex-start"}
            gap={theme.spacing(0.5)}
          >
            <img
              style={{
                width: "45px",
                height: "45px",
                objectFit: "contain",
              }}
              src={FindBrandLogo(brands, item.item.manufacturer.id)}
              alt={item.item.manufacturer.name_en}
            />
            <Typography color={theme.palette.secondary.dark} fontSize={"1rem"}>
              {item.item.part_number}
            </Typography>
          </Grid>
          <Grid
            xs={3}
            container
            direction={"row"}
            alignItems={"center"}
            justifyContent={"felx-start"}
          >
            <Typography
              color={theme.palette.secondary.main}
              fontSize={"0.9rem"}
            >
              {item.item.description}
            </Typography>
          </Grid>
          <Grid
            xs={4}
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Typography
              color={theme.palette.secondary.main}
              fontWeight={theme.typography.bold}
              fontSize={"1rem"}
            >
              {item.items_detail.selling_price !== 0
                ? item.items_detail.selling_price + " " + t("currency.SAR")
                : t("home.willBePriced")}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          padding={theme.spacing(1)}
          xs={12}
          gap={theme.spacing(1)}
          container
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Button
            disabled={quantity === 0}
            color="tertiary"
            onClick={() => {
              if (quantity > 0) {
                setQuantity((prevQuantity) => prevQuantity - 1);
                setItem({
                  id: item.id,
                  quantity: quantity - 1,
                });
              }
            }}
            sx={{
              padding: "0px",
              minWidth: "initial",
              width: "50px",
              height: "50px",
              borderRadius: "0.5rem",
              border: `1px solid ${theme.palette.gray.g200}`,
              "&:hover": {
                backgroundColor: theme.palette.gray.g100,
                border: `1px solid ${theme.palette.gray.g400}`,
              },
              backgroundColor: theme.palette.gray.g50,
              opacity: quantity === 0 && 0.5,
            }}
          >
            <RemoveIcon sx={{ color: "black" }} />
          </Button>
          <Input
            value={quantity}
            disableUnderline
            inputProps={{
              style: {
                textAlign: "center",
                padding: "0px",
                height: "50px",
              },
              onKeyPress: ValidateInputOnlyNum, // only enter numbers, no chars
            }}
            sx={{
              maxWidth: "4rem",
              fontWeight: theme.typography.bold,
              border: `1px solid ${theme.palette.gray.g200}`,
              borderRadius: "0.5rem",
              backgroundColor: theme.palette.gray.g50,
              width: { xs: "48px", semiSm: "64px", md: "60px" },
              height: "50px",
              padding: "0px",
            }}
            onClick={(e) => e.target.select()}
            onPaste={(e) => {
              e.preventDefault();
            }}
            onChange={(e) => handleChange(e)}
          />
          <Button
            disabled={quantity === 999999}
            color="tertiary"
            onClick={() => {
              setQuantity((prevQuantity) => prevQuantity + 1);
              setItem({ id: item.id, quantity: quantity + 1 });
            }}
            sx={{
              padding: "0px",
              minWidth: "initial",
              borderRadius: "0.5rem",
              border: `1px solid ${theme.palette.gray.g200}`,
              "&:hover": {
                backgroundColor: theme.palette.gray.g100,
                border: `1px solid ${theme.palette.gray.g400}`,
              },
              backgroundColor: theme.palette.gray.g50,
              width: "50px",
              height: "50px",
              opacity: quantity === 999999 && 0.5,
            }}
          >
            <AddIcon sx={{ color: "black" }} />
          </Button>
          <Grid />
        </Grid>
        <Grid xs={12} container sx={{ width: "100%" }}>
          <ButtonComp
            fullWidth
            content={
              putCartStatus !== "loading" ? (
                t("order.updateCart")
              ) : (
                <CircularProgress size={25} />
              )
            }
            variant="contained"
            color="secondary"
            sx={{
              borderRadius: `0px 0px ${theme.borderRadius.r15} ${theme.borderRadius.r15}`,
              height: "50px",
            }}
            onClick={handleSubmit}
          />
        </Grid>
      </Grid>
    </Modal>
  );
};

export default AddItemModal;
