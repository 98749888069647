import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Grid2,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import phone from "phone";
import { createInvoice } from "../../../app/features/wallet/wallet";
import { useDispatch, useSelector } from "react-redux";
import { ccyFormat } from "../../../utils/Helpers/general";
import i18next from "i18next";
import SuccessInvoiceModal from "./modals/SuccessInvoiceModal";
import { useNavigate } from "react-router-dom";

const CreateInvoiceForm = ({ selectedMerchantName, selectedMerchantObj }) => {
  //SECTION - general
  const theme = useTheme();
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //SECTION - useState
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [calculatedTaxAmount, setCalculatedTaxAmount] = useState();
  const [calculatedWalletGain, setCalculatedWalletGain] = useState();
  const [invoiceDetails] = useState({
    phone: "",
    firstName: "",
    lastName: "",
    email: "",
    totalPrice: "",
    finalPrice: "",
    note: "",
    acceptTermsAndConditions: false,
    payFees: false,
  });

  //SECTION - useSelector
  const companyBranchId = useSelector(
    (state) => state.walletReducer.companyBranchId
  );
  const clientWallets = useSelector(
    (state) => state.walletReducer.clientWallets
  );
  const createInvoiceStatus = useSelector(
    (state) => state.walletReducer.createInvoiceStatus
  );

  //SECTION - price validation
  const priceValidation = {
    Tabby: {
      min: 10,
      max: 8000,
    },
    Tamara: {
      min: 10,
      max: 8000,
    },
    MISPay: {
      min: 300,
      max: 4000,
    },
    Madfu: {
      min: 10,
      max: 8000,
    },
  };

  //SECTION - Yup Validation Schema
  const FORM_VALIDATION = Yup.object({
    phone: Yup.string()
      .test(
        "is-not-empty-or-spaces",
        t("wallet.yupNoSpaces"),
        function (value) {
          return value && value.trim() !== "";
        }
      )
      .matches(/^[^[\u0600-\u06FF\s]+$/u, t("wallet.yupPhoneNotInArabic"))
      .min(12, t("wallet.yupWritePhoneIn966Format"))
      // To validate phone and return true if it matches (966XXXXXXXX only)
      .test("phone", t("wallet.yupPhoneInvalid"), (value) => {
        const phoneNumber = phone(value, { country: "SA" });
        return phoneNumber.isValid && value.length === 12;
      })
      .required(t("wallet.yupPhoneRequired")),
    firstName: Yup.string()
      .test(
        "is-not-empty-or-spaces",
        t("wallet.yupNoSpaces"),
        function (value) {
          return value && value.trim() !== "";
        }
      )
      .test(
        "full-name-length",
        t("wallet.yupFullNameLength"),
        function (value) {
          const { lastName } = this.parent; // Get the value of lastName
          const fullNameLength = (value || "").length + (lastName || "").length;
          return fullNameLength > 6; // Check if the total length is greater than 6
        }
      )
      .required(t("wallet.yupNameRequired")),
    lastName: Yup.string()
      .test(
        "is-not-empty-or-spaces",
        t("wallet.yupNoSpaces"),
        function (value) {
          return value && value.trim() !== "";
        }
      )
      .test(
        "full-name-length",
        t("wallet.yupFullNameLength"),
        function (value) {
          const { firstName } = this.parent; // Get the value of firstName
          const fullNameLength =
            (firstName || "").length + (value || "").length;
          return fullNameLength > 6; // Check if the total length is greater than 6
        }
      )
      .required(t("wallet.yupNameRequired")),
    totalPrice: Yup.mixed()
      .test(
        "is-valid-number-format",
        t("wallet.valueIsNotNumber"),
        (value) => /^\d+(\.\d+)?$/.test(value) // Ensure it's a valid number format
      )
      .test(
        "is-integer",
        t("wallet.decimalsNotAllowed"),
        (value) => /^\d+$/.test(value) // Ensure it's an integer (no decimals allowed)
      )
      .test("is-within-range", function (value) {
        const totalPrice = parseFloat(value);
        const minPrice = priceValidation[selectedMerchantName]?.min;
        const maxPrice = priceValidation[selectedMerchantName]?.max;

        if (totalPrice < minPrice) {
          return this.createError({
            message: t("wallet.yupGreaterThan", {
              minPrice: minPrice,
            }),
          });
        }
        if (totalPrice > maxPrice) {
          return this.createError({
            message: t("wallet.yupMaxPrice", {
              maxPrice: maxPrice,
            }),
          });
        }
        return true;
      })
      .required(t("wallet.yupTotalPriceRequired")),
    acceptTermsAndConditions: Yup.boolean().oneOf(
      [true],
      t("wallet.yupAcceptTermsAndConditionsRequired")
    ),
    payFees: Yup.boolean().oneOf(
      [true, false],
      t("wallet.yupAcceptTermsAndConditionsRequired")
    ),
  });

  //SECTION - Formik
  const formik = useFormik({
    initialValues: { ...invoiceDetails },
    validationSchema: FORM_VALIDATION,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleSubmitForm(values);
    },
  });

  const handleSubmitForm = (values) => {
    const data = {
      company_branch_id: companyBranchId,
      amount: parseFloat(values.finalPrice),
      tax_amount: 4, //NOTE - hmmm....
      consumer_full_name: `${values.firstName} ${values.lastName}`,
      consumer_phone: values.phone,
      // consumer_email: values.email,
      consumer_email: `${values.phone}-${companyBranchId}@autobia-bnpl.com`,
      payment_gateway_type_id: selectedMerchantObj.id,
      commission_on_merchant: true, //Always true (business logic)
      note: values.note,
      platform: "Web Store",
    };

    dispatch(createInvoice(data)).then((res) => {
      if (res.payload?.status === true) {
        setOpenSuccessModal(true);
        formik.resetForm();
      }
    });
  };

  useEffect(() => {
    const handleTotalPriceChange = () => {
      if (formik.values.totalPrice !== "") {
        //Get tax and total price values
        const merchantTax = parseFloat(
          clientWallets.find(
            (wallet) =>
              wallet.payment_gateway_type?.id === selectedMerchantObj.id
          ).commission_rate
        );

        let finalTotalPirce = parseFloat(formik.values.totalPrice);

        //customer pay fees
        const payFees = formik.values.payFees;
        if (payFees) {
          finalTotalPirce = Math.floor(
            finalTotalPirce / (1 - merchantTax * (1 + 0.15))
          ).toFixed(2);
        }
        //calculate
        const taxAmount = (finalTotalPirce * merchantTax * 1.15).toFixed(2);
        let finalWalletGain = (finalTotalPirce - taxAmount).toFixed(2);

        //store values
        formik.setFieldValue("finalPrice", finalTotalPirce);
        setCalculatedTaxAmount(taxAmount);
        setCalculatedWalletGain(finalWalletGain);
      }
    };
    if (selectedMerchantObj) {
      handleTotalPriceChange();
    }
    // eslint-disable-next-line
  }, [
    formik.values.totalPrice,
    formik.values.payFees,
    selectedMerchantObj,
    clientWallets,
  ]);

  const RowComponent = ({ title, amount, includesVat = false }) => {
    return (
      <Card
        variant="outlined"
        style={{
          borderRadius: theme.borderRadius.r15,
          marginInline: 10,
          marginTop: 15,
        }}
      >
        <CardContent>
          <Typography sx={{ fontSize: 18 }} gutterBottom={!includesVat}>
            {title}
          </Typography>
          {includesVat ? (
            <Typography color={theme.palette.gray[500]} fontSize={11}>
              {t("wallet.includesVat")}
            </Typography>
          ) : (
            //TO MAKE ALL OF THE BOXES THE SAME HIEGHT
            <Box height={10}></Box>
          )}
          <Typography variant="h5" component="div">
            {!isNaN(amount) ? `${ccyFormat(amount)} ${t("wallet.riyal")}` : ""}
          </Typography>
        </CardContent>
      </Card>
    );
  };

  //SECTION - functions
  const handleCloseSuccessModal = (shouldNavigate) => {
    setOpenSuccessModal(false);
    formik.resetForm();
    if (shouldNavigate) {
      navigate("/wallet");
    }
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <SuccessInvoiceModal
        handleClose={handleCloseSuccessModal}
        open={openSuccessModal}
      />
      <Divider textAlign="left" sx={{ my: 2 }}>
        <Typography
          component={"p"}
          fontWeight={"bold"}
          fontSize={{ xs: "1.1rem", sm: "1.5rem" }}
        >
          {t("wallet.stepOne", {
            merchantName:
              i18n.languages[0] === "ar"
                ? selectedMerchantObj?.name_ar
                : selectedMerchantObj?.name_en,
          })}
        </Typography>
      </Divider>
      <Stack justifyContent={"center"} fullWidth textAlign={"center"}>
        <Container
          maxWidth="md"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(1),
          }}
        >
          <Grid
            container
            alignItems={{
              xs: "flex-start",
              sm: "center",
            }}
            flexDirection={{
              xs: "column",
              sm: "row",
            }}
          >
            <Grid
              xs={12}
              sm={3}
              textAlign={i18next.dir() === "ltr" ? "left" : "right"}
            >
              <Typography
                fontWeight={theme.typography.bold}
                fontSize={{
                  xs: "0.9rem",
                  sm: "1rem",
                }}
                color={theme.palette.secondary.main}
              >
                {t("wallet.phone")}
                <Typography component={"span"} sx={{ color: "red" }}>
                  *
                </Typography>
              </Typography>
            </Grid>
            <Grid xs={12} sm={9} sx={{ width: "100%" }}>
              <TextField
                color="secondary"
                fullWidth
                size="small"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
                placeholder="966500701616"
                InputProps={{
                  sx: {
                    borderRadius: "10px",
                    overflow: "hidden",
                    height: "40px",
                    backgroundColor: theme.palette.white,
                  },
                }}
                FormHelperTextProps={{
                  sx: {
                    textAlign: i18next.dir() === "ltr" ? "left" : "right",
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            alignItems={{
              xs: "flex-start",
              sm: "center",
            }}
            flexDirection={{
              xs: "column",
              sm: "row",
            }}
          >
            <Grid
              xs={12}
              sm={3}
              textAlign={i18n.dir() === "ltr" ? "left" : "right"}
            >
              <Typography
                fontWeight={theme.typography.bold}
                fontSize={{
                  xs: "0.9rem",
                  sm: "1rem",
                }}
                color={theme.palette.secondary.main}
              >
                {t("wallet.name")}
                <Typography component={"span"} sx={{ color: "red" }}>
                  *
                </Typography>
              </Typography>
            </Grid>
            <Grid
              xs={12}
              sm={9}
              container
              gap={theme.spacing(1)}
              sx={{ width: "100%" }}
            >
              <Grid xs>
                <TextField
                  color="secondary"
                  fullWidth
                  size="small"
                  name="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder={t("wallet.firstName")}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                  InputProps={{
                    sx: {
                      borderRadius: "10px",
                      overflow: "hidden",
                      height: "40px",
                      backgroundColor: theme.palette.white,
                    },
                  }}
                  FormHelperTextProps={{
                    sx: {
                      textAlign: i18n.dir() === "ltr" ? "left" : "right",
                    },
                  }}
                />
              </Grid>
              <Grid xs>
                <TextField
                  color="secondary"
                  fullWidth
                  size="small"
                  name="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder={t("wallet.lastName")}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                  InputProps={{
                    sx: {
                      borderRadius: "10px",
                      overflow: "hidden",
                      height: "40px",
                      backgroundColor: theme.palette.white,
                    },
                  }}
                  FormHelperTextProps={{
                    sx: {
                      textAlign: i18n.dir() === "ltr" ? "left" : "right",
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            alignItems={{
              xs: "flex-start",
              sm: "center",
            }}
            flexDirection={{
              xs: "column",
              sm: "row",
            }}
          >
            <Grid
              xs={12}
              sm={3}
              textAlign={i18next.dir() === "ltr" ? "left" : "right"}
            >
              <Typography
                fontWeight={theme.typography.bold}
                color={theme.palette.secondary.main}
                fontSize={{
                  xs: "0.9rem",
                  sm: "1rem",
                }}
              >
                {t("wallet.note")}
              </Typography>
            </Grid>
            <Grid xs={12} sm={9} sx={{ width: "100%" }}>
              <TextField
                color="secondary"
                fullWidth
                size="small"
                name="note"
                value={formik.values.note}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                InputProps={{
                  sx: {
                    borderRadius: "10px",
                    overflow: "hidden",
                    height: "40px",
                    backgroundColor: theme.palette.white,
                  },
                }}
                FormHelperTextProps={{
                  sx: {
                    textAlign: i18next.dir() === "ltr" ? "left" : "right",
                  },
                }}
              />
            </Grid>
          </Grid>
        </Container>

        <Divider textAlign="left" sx={{ my: 2 }}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            alignItems={{
              xs: "flex-start",
              sm: "center",
            }}
            gap={{ xs: 0, sm: theme.spacing(1) }}
          >
            <Typography
              component={"p"}
              fontWeight={"bold"}
              fontSize={{
                xs: "1.1rem",
                sm: "1.5rem",
              }}
            >
              {t("wallet.stepTwo")}
            </Typography>
            <Typography
              fontSize={{
                xs: "0.7rem",
                sm: "0.9rem",
              }}
              color={theme.palette.gray.g500}
            >
              {`(${t("wallet.includeServiceFee")})`}
            </Typography>
          </Stack>
        </Divider>

        <Container
          maxWidth="md"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(1),
          }}
        >
          <Grid
            container
            alignItems={{
              xs: "flex-start",
              sm: "center",
            }}
            flexDirection={{
              xs: "column",
              sm: "row",
            }}
          >
            <Grid
              xs={12}
              sm={3}
              textAlign={i18next.dir() === "ltr" ? "left" : "right"}
            >
              <Typography
                fontWeight={theme.typography.bold}
                color={theme.palette.secondary.main}
                fontSize={{
                  xs: "0.9rem",
                  sm: "1rem",
                }}
              >
                {`${t("wallet.totalPrice")} (VAT 15%)`}
                <Typography component={"span"} sx={{ color: "red" }}>
                  *
                </Typography>
              </Typography>
            </Grid>
            <Grid xs={12} sm={9} sx={{ width: "100%" }}>
              <TextField
                color="secondary"
                fullWidth
                size="small"
                name="totalPrice"
                value={formik.values.totalPrice}
                onChange={(e) => {
                  const value = e.target.value;
                  const validValue = value.match(/^\d*\.?\d*$/);

                  if (validValue) {
                    formik.setFieldValue("totalPrice", value);
                  }
                }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.totalPrice && Boolean(formik.errors.totalPrice)
                }
                helperText={
                  formik.touched.totalPrice && formik.errors.totalPrice
                }
                InputProps={{
                  sx: {
                    borderRadius: "10px",
                    overflow: "hidden",
                    height: "40px",
                    backgroundColor: theme.palette.white,
                    borderBottom: "0px transparent solid",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography
                        color="secondary"
                        fontWeight={theme.typography.bold}
                      >
                        {t("currency.SAR")}
                      </Typography>
                    </InputAdornment>
                  ),
                }}
                FormHelperTextProps={{
                  sx: {
                    textAlign: i18next.dir() === "ltr" ? "left" : "right",
                  },
                }}
              />
            </Grid>
            <Grid
              xs={12}
              sm={3}
              textAlign={i18next.dir() === "ltr" ? "left" : "right"}
            ></Grid>
            <Grid xs={12} sm={9} sx={{ width: "100%" }}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      name="payFees"
                      checked={formik.values.payFees}
                      onChange={(e) => {
                        formik.setFieldValue("payFees", e.target.checked);
                      }}
                    />
                  }
                  label={
                    <Typography
                      textAlign={i18n.languages[0] === "ar" ? "right" : "left"}
                      fontSize={"0.9rem"}
                    >
                      {t("wallet.customerPayFees")}
                    </Typography>
                  }
                />
              </FormGroup>
              {formik.values.payFees && (
                <Box
                  sx={{
                    borderRadius: theme.borderRadius.r10,
                    backgroundColor: theme.palette.secondary.light,
                  }}
                >
                  <Grid2 container flexDirection={"row"}>
                    <Box
                      width={"3%"}
                      maxWidth={15}
                      sx={{
                        backgroundColor: theme.palette.secondary.main,
                        borderStartStartRadius: theme.borderRadius.r5,
                        borderEndStartRadius: theme.borderRadius.r5,
                      }}
                    ></Box>

                    <Box width={"97%"}>
                      <Typography fontSize={"0.8rem"} margin={1}>
                        {t("wallet.customerPayFeesDisclaimer")}
                      </Typography>
                    </Box>
                  </Grid2>
                </Box>
              )}
              <FormHelperText
                sx={{
                  textAlign: i18next.dir() === "ltr" ? "left" : "right",
                  px: theme.spacing(4),
                  m: 0,
                }}
                error={Boolean(formik.touched.payFees && formik.errors.payFees)}
              >
                {formik.touched.payFees && formik.errors.payFees}
              </FormHelperText>
            </Grid>
            {!isNaN(formik.values.finalPrice) &&
            !isNaN(calculatedWalletGain) &&
            !isNaN(calculatedTaxAmount) &&
            formik.values.totalPrice > 10 ? (
              <Grid
                container
                justifyContent="space-around"
                alignItems="center"
                rowSpacing={1}
                columnSpacing={1}
              >
                <Grid item xs={12} md={4}>
                  <RowComponent
                    title={t("wallet.clientWillPay")}
                    amount={formik.values.finalPrice}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <RowComponent
                    title={t("wallet.SeviceFees")}
                    amount={calculatedTaxAmount}
                    includesVat={true}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <RowComponent
                    title={t("wallet.goToYourWallet")}
                    amount={calculatedWalletGain}
                  />
                </Grid>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </Container>

        <Divider textAlign="left" sx={{ my: 2 }}>
          <Typography
            component={"p"}
            fontWeight={"bold"}
            fontSize={{
              xs: "1.1rem",
              sm: "1.5rem",
            }}
          >
            {t("wallet.stepThree")}
          </Typography>
        </Divider>

        <Container
          maxWidth="md"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(1),
          }}
        >
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  value={formik.values.acceptTermsAndConditions}
                  color="secondary"
                  onChange={(e) => {
                    formik.setFieldValue(
                      "acceptTermsAndConditions",
                      e.target.checked
                    );
                  }}
                />
              }
              label={
                <Typography
                  textAlign={i18n.languages[0] === "ar" ? "right" : "left"}
                  fontSize={"0.9rem"}
                >
                  {t("wallet.acceptT&C")}
                  <Typography component={"span"} sx={{ color: "red" }}>
                    *
                  </Typography>
                </Typography>
              }
            />
          </FormGroup>
          <FormHelperText
            sx={{
              textAlign: i18next.dir() === "ltr" ? "left" : "right",
              px: theme.spacing(4),
              m: 0,
            }}
            error={Boolean(
              formik.touched.acceptTermsAndConditions &&
                formik.errors.acceptTermsAndConditions
            )}
          >
            {formik.touched.acceptTermsAndConditions &&
              formik.errors.acceptTermsAndConditions}
          </FormHelperText>
          <Button
            color="secondary"
            variant="contained"
            sx={{
              borderRadius: theme.borderRadius.r10,
              py: theme.spacing(1.5),
            }}
            fullWidth
            disabled={
              !(formik.isValid && formik.dirty) ||
              createInvoiceStatus === "loading"
            }
            onClick={formik.handleSubmit}
          >
            {createInvoiceStatus === "loading" ? (
              <CircularProgress size={24} />
            ) : (
              t("wallet.createInvoice")
            )}
          </Button>
        </Container>
      </Stack>
    </form>
  );
};

export default CreateInvoiceForm;
